import React, { useEffect } from 'react';
import { connect } from 'react-redux'; 
import PropTypes from 'prop-types';
import { Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '../../reusableComponents/DashboardCard';
import { getAllCourses } from '../../redux/actions/CoursesActions';
import { getAllUsers, getAllAdmins } from '../../redux/actions/AccountsActions';
import isEmpty from '../../reusableComponents/is-empty';
//import DashboardTable from './DashboardTable'; 
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }, 
    table:{
        paddingTop:20,
        marginTop:4
    },

}));

const Dashboard = ({
    getAllCourses,
    getAllUsers,
    getAllAdmins,
    accounts,
    courses,
    auth
}) => {
    const classes = useStyles();

    const { isAuthenticated } = auth;
    const { coursesList } = courses;
    const { accountUsersList, accountAdminsList } = accounts;

    useEffect(() => {
        if(isEmpty(coursesList) && isEmpty(accountUsersList) && isEmpty(accountAdminsList) && isAuthenticated) {
            getAllCourses();
            getAllUsers();
            getAllAdmins();
        }
    }, [coursesList, accountUsersList, accountAdminsList, isAuthenticated])

    return ( 
        <Container>
        <Grid container spacing={5}> 
            <Grid item xs={12} sm={6} md={4}> 
                <Link to="/admin/courses" style={{textDecoration:"none"}}> 
                <Card 
                 title="Total Courses"  
                 count={coursesList?.length} 
                 /> 
                  </Link> 
            </Grid> 
            
            <Grid item xs={12} sm={6} md={4}> 
            <Link to ="/admin/users" style={{textDecoration:"none"}}>
                <Card 
                    title="Total Users"
                    count={accountUsersList?.length}
                /> 
            </Link>
            </Grid> 

            <Grid item xs={12} sm={6} md={4}> 
            <Link to ="/admin/adminUsers" style={{textDecoration:"none"}}>
                <Card 
                    title="Total Admins"
                    count={accountAdminsList?.length}
                /> 
            </Link>
            </Grid>  


        </Grid>  
        {/* <div className={classes.table}>
        <DashboardTable/> 
        </div> */}
        </Container>  

    );
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    getAllCourses: PropTypes.func.isRequired,
    getAllAdmins: PropTypes.func.isRequired,
    getAllUsers: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    courses: state.courses,
    accounts: state.accounts
})

const mapDispatchToProps = { getAllCourses, getAllAdmins, getAllUsers }

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);