import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#003399',
    '&:hover': {
      backgroundColor: '#003399'
    },
  },
}))(MuiButton);

export default function ButtonField({ 
  name, 
  type,
  variant, 
  buttonStyle, 
  onClick, 
  icon, 
  component, 
  fileTag 
}) {
  return (
    <ColorButton type={type} variant={variant || "contained"} component={component} color="primary" className={buttonStyle} onClick={onClick}>
        {icon} {name} {fileTag}
    </ColorButton>
  )
}
