import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  Button,
  Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
// import { alpha, Box, Grid, Card, CardContent } from "@material-ui/core";
// import CardMedia from "@material-ui/core/CardMedia";
// import CardActions from "@material-ui/core/CardActions";
// import CardActionArea from "@material-ui/core/CardActionArea";

//import DashboardTable from '../components/Admin_View/DashboardTable';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    paddingTop: 10,
  },
  // },
  displaycard: {
    height: "200px",
    width: "300px",
    //height: "auto",
    //marginLeft: "40px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "290px",
      marginLeft: "20px",
    },
    color: "red",
    borderRadius: "20px",
  },

  //    section:{
  //        position:'absolute',
  //        top:"50%",
  //        left:"50%",
  //        transform:"transalte(-50%,-50%)",
  //        hegiht: "400px",
  //        width:"600px",
  //        backgroundColor:"red"
  // wraper: {
  //   width: "100%",
  //   height: "330px",
  //   //background: "#000",
  //   display: "flex"
  // },

  divone: {
    width: "calc( 50% - 80px)",
    height: "100%",
    background: "#003399",
    color: "white",
  },

  divtwo: {
    width: "calc( 50% + 80px )",
    height: "100%",
    //color: "black",
    //backgroundColor: "red",
    //borderLeft: "100px solid #003399",
    borderBottom: "330px solid transparent",
    boxSizing: "border-box",
  },

  contents: {
    color: "black",
    fontSize: "16px",
    padding: "15px",
  },
  head: {
    textAlign: "center",
    paddingTop: "50px",
    paddingRight: "30px",
  },
  // text:{
  //   color:"white",
  //   textAlign:"center"
  // }
}));

export default function DashboardCard({ title, count }) {
  const classes = useStyles();

  return (
    <Container>
      <div className="dashboardcardcontainer">
        <Typography className={classes.head} variant="h6">
          {" "}
          {title}{" "}
        </Typography>
        <Typography className="textbottom"> {count} </Typography>
      </div>
    </Container>
  );
}
