import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageHeader from "../../../reusableComponents/PageHeader";
import Tables from "../../MaterialTable/MaterialTable";
import isEmpty from "../../../reusableComponents/is-empty";
import { getAllAdmins } from "../../../redux/actions/AccountsActions";
import DialogModal from "../../../reusableComponents/Modal/DialogModal";
import AddNew from "./AddNew";
import { Container,Paper } from "@material-ui/core"; 
import { makeStyles } from "@material-ui/core/styles";
import { GroupAdd as GroupAddIcon} from "@material-ui/icons";   


const useStyles = makeStyles(theme => ({
  pageContent: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(0),
  },
}))

const AdminUsers = ({ getAllAdmins, auth, accounts }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const { isAuthenticated } = auth;
  const { accountAdminsList } = accounts;

  useEffect(() => {
    if (isEmpty(accountAdminsList) && isAuthenticated) {
      getAllAdmins();
    }
  }, [accountAdminsList, isAuthenticated]);

  const columns = [
    {
      title: "First Name",
      field: "firstName",
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "User Name",
      field: "userName",
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      //color: "#3d4977",
      color: "white",
    },
    scrollable: true,
    pageSize: 7,
    searchFieldStyle: {
      width: "100%",
    },
  };
  
  const classes = useStyles();
  return (
    <> 
      <Container maxWidth="lg" >  
      <Paper className={classes.pageContent}>
      <PageHeader
        title="Admin's List"
        subTitle="All admin's list"
        icon={<GroupAddIcon fontSize="large" />}
      /> 
       </Paper> 

      <Tables
        title="Admin's List"
        columns={columns}
        data={accountAdminsList}
        searchField
        addNewText="Create New Admin"
        addOnClick={() => setOpenPopup(true)}
        selectedRow={(list) => console.log("")}
        options={options}
      />

      <DialogModal
        title="Create New Admin"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddNew type="admin" />
      </DialogModal> 
      </Container>
    </>
  );
};

AdminUsers.propTypes = {
  getAllAdmins: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
});

const mapDispatchToProps = { getAllAdmins };

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
