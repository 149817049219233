import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Layout from "./Layout";
import Welcome from "./components/Welcome/Welcome";
import SignIn from "./components/SignIn_SignUp/SignIn";

import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";

import Courses from "./components/Courses/Courses";
import AllCourses from "./components/Courses/AllCourses";
import CourseView from "./components/Courses/CourseView";
import VideoView from "./components/Courses/VideoView";
import FreeCourseView from "./components/Courses/FreeCourseView";
import FreeVideoView from "./components/Courses/FreeVideoView";

// Admin Route
import AdminRoutes from "./AdminRoutes";
import UserEditProfile from "./components/UserProfile/UserEditProfile";
import PasswordReset from "./components/PasswordReset/PasswordReset";

//Ibridge
import IbridgeLanding from "./components/Ibridge/IbridgeLanding";

const App = ({ location }) => {
  const [bgColor, setBgcolor] = useState("#ffffff");

  const { pathname } = location;

  useEffect(() => {
    if (pathname === "/") {
      setBgcolor("#ffffff");
    } else {
      // setBgcolor("#fafafa");
      setBgcolor("#ffffff");
    }
  }, [pathname]);

  return (
    <Switch>
      {/* <div style={{ background: bgColor }}> */}
        {/**---- Admin Main Route ----**/}
        <PrivateRoute path="/admin" component={AdminRoutes} />
        <Layout>
          <Route exact path="/" component={Welcome} />
          <Route path="/sign-In" component={SignIn} />
          <Route path="/forgot-Password" component={ForgotPassword} />
          <Route path="/reset-Password/:token" component={ResetPassword} />
          <Route path="/explore-courses" component={AllCourses} />
          <Route
            exact
            path="/course-view/:courseId"
            component={FreeCourseView}
          />
          <Route
            exact
            path="/preview/:courseId/:videoId"
            component={FreeVideoView}
          />
          <Route
            exact
            path="/ibridge/:ilearn/:courseId"
            component={IbridgeLanding}
          />
          <PrivateRoute path="/courses" component={Courses} />
          <PrivateRoute exact path="/course/:courseId" component={CourseView} />
          <PrivateRoute
            exact
            path="/course/:courseId/:videoId"
            component={VideoView}
          />
          <PrivateRoute path="/editProfile" component={UserEditProfile} />
          <PrivateRoute path="/Passwordreset" component={PasswordReset} />
        </Layout>
      {/* </div> */}
    </Switch>
  );
};

export default withRouter(App);
