import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import isEmpty from "./is-empty";
import { BsExclamationDiamond } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

const SelectMultipleValues = ({
  showLable,
  heading,
  values,
  onSelectValues,
  errors,
  refs,
  name,
  selectedValues,
  className,
  disabled,
  placeholder,
  label,
  size,
}) => {
  const classes = useStyles();
  const errorText = (
    <p>
      <BsExclamationDiamond /> {errors}
    </p>
  );
  return (
    <div className={classes.root}>
      {showLable && <div className="fieldHeading">{heading}</div>}
      {!isEmpty(values) && (
        <Autocomplete
          multiple
          id="tags-outlined"
          options={values}
          getOptionLabel={(option) => option.title}
          defaultValue={selectedValues}
          value={selectedValues}
          filterSelectedOptions
          onChange={(event, value) => {
            onSelectValues(value);
          }}
          size={size || "small"}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              error={errors ? true : false}
              label={label}
              helperText={errors ? errorText : null}
              disabled={disabled || false}
              placeholder={placeholder}
              inputRef={refs}
              className={className ? className : null}
              id={name}
              name={name}
              // InputProps={{
              //     className: className
              // }}
            />
          )}
        />
      )}
    </div>
  );
};

export default SelectMultipleValues;
