import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Link, NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Logo from "../../images/ibi.png";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  useScrollTrigger,
  IconButton,
  Button,
  Slide,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
  MoreVert as MoreIcon,
  ViewList as ViewListIcon,
  PhoneInTalk as PhoneInTalkIcon,
} from "@material-ui/icons";
import { logoutUser } from "../../redux/actions/authActions";
import ButtonField from "../../reusableComponents/ButtonField";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import SearchBar from "../SearchBar/SearchBar";

const drawerWidth = 240;
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  //   menuContainer: {
  //     marginLeft: theme.spacing(2)
  // },

  menuButton: {
    marginRight: theme.spacing(1),
    display: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  // menuLeft: {
  //   marginLeft:  theme.spacing(8),
  //   marginLeft: '1100px'
  // },
  menuContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
    //marginLeft: "580px",
    algin: "right",
  },

  menu: {
    margin: theme.spacing(3),
    alignItems: "left",
    justifyContent: "",
    //marginLeft: " 90px" ,
    // position:"relative"
  },

  // heading:{
  //   color:"black",
  //   textDecoration:"none"
  // },

  title: {
    //marginLeft: '100px',
    justifyContent: "center",

    fontSize: 19,
    fontWeight: 600,
    textDecoration: "none",
    color: " black",
    cursor: "pointer",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "&:hover": {
      color: "#003399",
      //backgroundColor:"#003399",
    },
  },
  // titlehome: {
  //   marginLeft: '300px',
  //   fontSize: 19,
  //   fontWeight: 600,
  //   textDecoration: "none",
  //   color: "#2B2B2B",
  //   cursor: "pointer",
  //   display: "none",
  //   [theme.breakpoints.up("sm")]: {
  //     display: "block",
  //   },'&:hover': {
  //           color: '#008dd2'
  //       }
  // },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  mobileTitle: {
    color: "#666666",
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 600,
  },
  titleActive: {
    color: "#003399",
    textDecorationLine: "underline",
  },
  userDetails: {
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
  },
  profileIconButton: {
    //background: "#3bb2d0",
    border: "1px solid #003399",
    borderRadius: 25,
    color: " #003399",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid #003399",
      //background: "#3bb2d0",
      color: " #003399",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      // background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // color: '#F8F9F9',
      // '&:hover': {
      //   background: '#1e293a'
      // }
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  headerButton: {
    maxWidth: "300px",
  },
  active: {
    color: "#003399",
    textDecorationLine: "underline",
  },
}));

const Header = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileMenuIcon, setMobileMenuIcon] = useState("closed");
  const [mobileDrawrOpen, setMobileDrawrOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileDrawrOpen(!mobileDrawrOpen);
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const { isAuthenticated, user } = props.auth;
  const theme = useTheme();
  const { pathname } = props.location;
  const [activeTitle, setActiveTitle] = useState(
    user?.programsEnrolled?.length > 0 ? "mvk" : "MyPrograms"
  );

  const dummyCategories = [
    {
      id: 1,
      icon: <HomeIcon />,
      name: "Home",
      route: "/",
    },
    {
      id: 2,
      icon: <ViewListIcon />,
      name: "Courses",
      route: "/explore-courses",
    },
    {
      id: 3,
      icon: <ViewListIcon />,
      name: "MyCourses",
      route: "/courses",
    },
    {
      id: 4,
      icon: <PhoneInTalkIcon />,
      name: "Contact Us",
      route: "/contact-Us",
    },
  ];

  const drawer = (
    <div>
      <List>
        {dummyCategories.map((list, index) => (
          <ListItem
            button
            key={list.id}
            selected={pathname === list.route}
            onClick={() => {
              props.history.push(list.route);
              handleDrawerToggle();
            }}
          >
            <ListItemIcon className={classes.icon}>{list.icon}</ListItemIcon>
            <ListItemText primary={list.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className="headerContainer">
          <Toolbar>
            {/* <a href="https://www.ibridge360.com/">
                    <img
                      src={Logo}
                      style={{
                        width: "205px",
                        height: "60px",
                        objectFit: "cover", 
                        marginRight:"30px"
                      }}
                    />
                  </a> */}
            <Container maxWidth="lg" className="headerContent">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              {!isAuthenticated ? (
                <>
                  <a href="https://www.ibridge360.com/">
                    <img
                      src={Logo}
                      style={{
                        width: "205px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                  <div className={classes.grow} />
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      {/* <Link to="/" className={classes.title}>
                        Home
                      </Link> */}
                      <a
                        href="https://www.ibridge360.com/"
                        className={classes.title}
                      >
                        Home
                      </a>
                    </Typography>
                  </div>
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link to="/explore-courses" className={classes.title}>
                        Courses
                      </Link>
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={Logo}
                    style={{
                      width: "205px",
                      height: "60px",
                      objectFit: "cover",
                      marginLeft: "-10px",
                    }}
                  />

                  {/* <div className={classes.menu}>
                    <Typography variant="h5" >
                      {/* <Link to="/courses"> My Courses</Link>  */}
                  {/* <NavLink to="/courses"  className={classes.title}  activeClassName={classes.active} >  My Courses </NavLink> 
                    </Typography>
                  </div>
                  <div className={classes.menu} >
                    <Typography variant="h5"  > 
                    <NavLink to="/explore-courses" className={classes.title}  activeClassName={classes.active}> Explore Courses </NavLink>  */}
                  {/* <Link to="/explore-courses" > Explore Courses</Link>     activeClassName={classes.active}  */}
                  {/* </Typography>
                  // </div>  
                  // */}
                  {/* <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/courses"
                        className={
                          pathname === "/courses"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                       MyCourses
                      </Link>
                    </Typography>
                  </div> 
                  <div className={classes.menuContainer}>
                    <Typography variant="h5" noWrap>
                      <Link
                        to="/explore-courses"
                        className={
                          pathname === "/explore-courses"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                       Explore Courses
                      </Link>
                    </Typography>
                  </div> */}
                </>
              )}
              <div className={classes.grow} />
              {!isAuthenticated ? (
                <>
                  <div className={classes.sectionDesktop}>
                    <ButtonField
                      name="Login"
                      buttonStyle="headerButton"
                      onClick={() => props.history.push("/sign-In")}
                    />
                  </div>
                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>

                    <Menu
                      id="menu-appbar"
                      anchorEl={mobileMoreAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(mobileMoreAnchorEl)}
                      onClose={handleMobileMenuClose}
                    >
                      <MenuItem onClick={handleMobileMenuClose}>
                        <Typography variant="h5" noWrap>
                          <Link
                            to="/sign-In"
                            className={
                              pathname === "/sign-In"
                                ? clsx(classes.mobileTitle, classes.titleActive)
                                : classes.mobileTitle
                            }
                          >
                            Login
                          </Link>
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <div className={classes.userDetails}>
                  <div className={classes.sectionDesktop}>
                    <Button
                      disableRipple
                      className={classes.profileIconButton}
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      variant="outlined"
                      onClick={handleMenu}
                    >
                      <AccountCircle className={classes.profileIcon} />{" "}
                      {user?.firstName}{" "}
                      {Boolean(anchorEl) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Button>
                  </div>

                  <Menu
                    className={classes.profileDrop}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        props.history.push("/editProfile");
                        handleClose();
                      }}
                    >
                      <PersonIcon /> EditProfile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.history.push("/Passwordreset");
                        handleClose();
                      }}
                    >
                      <VpnKeyIcon /> ResetPassword
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        props.logoutUser();
                        handleClose();
                      }}
                    >
                      <ExitToAppIcon /> Logout
                    </MenuItem>
                  </Menu>

                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>

                    <Menu
                      id="menu-appbar"
                      anchorEl={mobileMoreAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(mobileMoreAnchorEl)}
                      onClose={handleMobileMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          props.history.push("/editProfile");
                          handleClose();
                          handleMobileMenuClose();
                        }}
                      >
                        <PersonIcon /> EditProfile
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          props.history.push("/Passwordreset");
                          handleClose();
                          handleMobileMenuClose();
                        }}
                      >
                        <VpnKeyIcon /> ResetPassword
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          props.logoutUser();
                          handleClose();
                          handleMobileMenuClose();
                        }}
                      >
                        <ExitToAppIcon /> Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              )}
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Toolbar />
      {isAuthenticated && user.role.r_id == 2 ? (
        <nav className="navBar">
          {/* <h2 className="navBarTitle">My Learning</h2>  */}

          <div
            className="menu-icon"
            onClick={() => {
              mobileMenuIcon === "closed"
                ? setMobileMenuIcon("expand")
                : setMobileMenuIcon("closed");
            }}
          >
            {mobileMenuIcon === "closed" ? (
              <MenuIcon style={{ color: "white" }} />
            ) : (
              <CloseIcon style={{ color: "white" }} />
            )}
          </div>
          <ul
            className={
              mobileMenuIcon === "closed" ? "navMenu" : "navMenu active "
            }
          >
            {user?.programsEnrolled?.length > 0 ? (
              <>
                <li
                  className={
                    activeTitle === "MyCourses" ? "menuItems1" : "menuItems"
                  }
                >
                  <Link
                    to="/courses"
                    onClick={() => {
                      setActiveTitle("MyCourses");
                      setMobileMenuIcon("closed");
                    }}
                    className="nav-links"
                  >
                    MyCourses
                  </Link>
                </li>{" "}
                <li
                  className={
                    activeTitle === "exploreCourses"
                      ? "menuItems1"
                      : "menuItems"
                  }
                >
                  <Link
                    to="/explore-courses"
                    onClick={() => {
                      setActiveTitle("exploreCourses");
                      setMobileMenuIcon("closed");
                    }}
                    className="nav-links"
                  >
                    Explore Courses
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={
                    activeTitle === "MyCourses" ? "menuItems1" : "menuItems"
                  }
                >
                  <Link
                    to="/courses"
                    onClick={() => {
                      setActiveTitle("MyCourses");
                      setMobileMenuIcon("closed");
                    }}
                    className="nav-links"
                  >
                    My Courses
                  </Link>
                </li>

                <li
                  className={
                    activeTitle === "Explore Courses"
                      ? "menuItems1"
                      : "menuItems"
                  }
                >
                  <Link
                    to="/explore-courses"
                    onClick={() => {
                      setActiveTitle("ExploreCourses");
                      setMobileMenuIcon("closed");
                    }}
                    className="nav-links"
                  >
                    Explore Courses
                  </Link>
                </li>
              </>
            )}

            <li
              className={
                activeTitle === "Recommended" ? "menuItems1" : "menuItems"
              }
            >
              {/* <Link
                to="/programs"
                onClick={() => {
                  setActiveTitle("Recommended");
                  setMobileMenuIcon("closed");
                }}
                className="nav-links"
              >
              Assigned Courses
              </Link>  */}
            </li>
            {/* <li  className={activeTitle==="Status"? "menuItems1" : "menuItems"}>
              <a onClick={()=>{
                                setActiveTitle("Status")
                              }} className="nav-links" href="#">
                Status
              </a>
            </li>
            <li  className={activeTitle==="Notification"? "menuItems1" : "menuItems"}>
              <a onClick={()=>{
                                setActiveTitle("Notification")
                              }} className="nav-links" href="#">
                Notification
              </a>
            </li> */}
            {props?.location?.pathname === "/courses" ||
              (props?.location?.pathname === "/explore-courses" && (
                <div
                  style={{
                    position: "relative",
                    left: 690,
                  }}
                  className="searchBar"
                >
                  <SearchBar userId={user._id} />
                </div>
              ))}
          </ul>
        </nav>
      ) : null}

      {props.children}

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileDrawrOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
