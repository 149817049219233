import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllCourses } from "../../../redux/actions/CoursesActions";
import Table from "../../MaterialTable/MaterialTable";
import PageHeader from "../../../reusableComponents/PageHeader";
import ListAltIcon from "@material-ui/icons/ListAlt";
import isEmpty from "../../../reusableComponents/is-empty"; 
import { makeStyles, Paper ,Container} from '@material-ui/core';   


const useStyles = makeStyles(theme => ({ 

  header:{
      margin: theme.spacing(0, 0),
      padding: theme.spacing(1)
  },
  pageContent: {
      margin: theme.spacing(0, 0),
      padding: theme.spacing(0)
  } ,
  page:{
      padding: theme.spacing(3)
  } 

}))

const Courses = ({ getAllCourses, courses, auth, history }) => {
  const { isAuthenticated } = auth;
  const { coursesList } = courses;

  useEffect(() => {
    if (isEmpty(coursesList) && isAuthenticated) {
      getAllCourses();
    }
  }, [coursesList, isAuthenticated]);

  const columns = [
    {
      title: "Image",
      field: "image",
      disableSorting: true,
      cellStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerStyle: {
        width: 100,
        maxWidth: 100,
      },
      render: (rowData) => (
        <img style={{ height: 50 }} src={rowData.image} alt="" />
      ),
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Duration",
      field: "duration",
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      //color: "#3d4977",
      color: "white",
    },
    scrollable: true,
    pageSize: 7,
    searchFieldStyle: {
      width: "100%",
    },
  };

  const data = coursesList?.map((list) => {
    const { _id, title, courseImage, duration } = list;
    const length = new Date(1000 * (duration ? duration : 0))
      .toISOString()
      .substr(11, 8)
      .replace("00:", "");
    return {
      _id: _id,
      name: title,
      image: courseImage?.link,
      duration: length || "Course Duration not available",
    };
  });
   
  const classes = useStyles();

  return (
    <> 
     <Container maxWidth="lg" className={classes.header}> 
      <Paper className={classes.pageContent}>
              <PageHeader
                title="Course List"
                subTitle="List of all courses"
                icon={<ListAltIcon fontSize="large" />}
              /> 
      </Paper>
      <Table
        title="Course List"
        columns={columns}
        data={data}
        searchField
        addNewText="Add New Course"
        addOnClick={() => history.push("/admin/newCourse")}
        selectedRow={(course) => history.push(`/admin/course/${course._id}`)}
      /> 
      </Container>
    </>
  );
};

Courses.propTypes = {
  getAllCourses: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = { getAllCourses };

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
