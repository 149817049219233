import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Tab, Tabs } from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import {
  Container,
  Grid,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Toolbar,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
} from "../../redux/actions/CoursesActions";
import { setCurrentUser } from "../../redux/actions/authActions";
import { getVideoProgress } from "../../redux/actions/AccountsActions";
import {
  RiVideoAddLine,
  RiFile3Fill,
  RiCheckboxFill,
  RiPushpin2Fill,
} from "react-icons/ri";
import FileView from "./FileViews";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import DialogModal from "../../reusableComponents/Modal/DialogModal";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Menu } from "@material-ui/icons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import history from "../../reusableComponents/history";

const useStyles = makeStyles((theme) => ({
  courseViewContaier: {
    margin: theme.spacing(0, 3),
  },
  title: {
    marginLeft: theme.spacing(55),
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 650,
    marginBottom: theme.spacing(1),
    color: "#003399",
  },

  panelTitle: {
    fontSize: 16,
    fontWeight: 650,
    marginRight: 100,
  },
  panel: {
    fontWeight: 650,
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
  },

  courseDisplay: {
    display: "flex",
    padding: "8px",
    //alignItems: "center",
    // margin: theme.spacing(1, 0),
  },
  courseTitle: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  courseTitleGreen: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  courseTitleRed: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "red",
    fontWeight: 600,
    "&:hover": {
      color: "red",
      cursor: "default",
    },
  },
  courseCardImageContainer: {
    position: "relative",
    boxShadow: "0 5px 10px rgba(0,0,0,.05)",
    border: "1px solid #e4e4e4",
  },

  mediaImage: {
    height: 500,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "#fff",
  },
  mediaImageMobile: {
    height: 300,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "#fff",
  },
  card: {
    border: "3px solid rgba(0, 0, 0, .125)",
    padding: "10px",
    backgroundColor: "#5e72e4",
    color: "white",
  },
  courseInfoViewContainer: {
    position: "relative",
    margin: "20px auto",
    marginBottom: "0",
  },

  scrollable: {
    width: "100%",
    backgroundColor: "#f1f1f1",
    height: "470px",
    // width: "400px",
    overflowY: "scroll",
    //margin:'15px',
    marginLeft: "7px",
    //textAlign: 'justify',
    padding: "15px",
    paddingRight: "0px",
  },
}));

const CourseView = ({
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
  setCurrentUser,
  courses,
  match,
  history,
  accounts,
  getVideoProgress,
  auth,
}) => {
  const classes = useStyles();
  //const [expanded, setExpanded] = useState("panel1");
  const { user, isAuthenticated } = auth;
  const [value, setValue] = React.useState("1");
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);
  const { userCourseVideoProgress } = accounts;
  const [openPopup, setOpenPopup] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const DrawerList = ["vishwas", "vikas"];

  const { particularCourse, documents, courseVideosAndDocuments } = courses;
  const { courseId } = match.params;

  useEffect(() => {
    getParticularCourse(courseId);
    getCourseDocuments(courseId);
    getVideosAndDocuments(courseId);
    getVideoProgress(courseId);
    const data = {
      userId: user?._id,
      courseId: courseId,
    };
    getVideoProgress(data);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.assignedCourses) {
        const courseAssigned = user?.assignedCourses?.find(
          (item) => item === courseId
        );
        if (!courseAssigned) {
          history.push("/courses");
        }
      } else {
        setCurrentUser();
      }
    }
  }, [auth]);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleFile = (data) => {
    console.log("doc data", data?.document?.link);
    setFile(data?.document?.link);
    setDisplayFile(true);
    // setOpenPopup(true);
    window.scrollTo(0, 0);
    setOpenDrawer(false);
  };

  const videoContent = courseVideosAndDocuments?.map((item) => {
    if (userCourseVideoProgress?.find((c) => c.videoId === item._id)) {
      item.completed = userCourseVideoProgress?.find(
        (c) => c.videoId === item._id
      ).completed;
      return item;
    }
    item.completed = false;
    return item;
  });

  const handleRestrictedVideos = (list) => {
    if (list.prerequisiteVideos?.length > 0) {
      const viewedVideos = list.prerequisiteVideos?.filter((el) => {
        return !userCourseVideoProgress.some((f) => {
          return f.videoId === el._id;
        });
      });
      if (viewedVideos.length === 0) {
        history.push(`/course/${courseId}/${list._id}`);
      } else {
        SnackBar.error(
          `You have to finish ${viewedVideos
            .map((item) => item.title)
            .toString()} videos to unlock this video `
        );
      }
    } else {
      return (window.location.href = `/course/${courseId}/${list._id}`);
      // history.push(`/course/${courseId}/${list._id}`);
    }
  };

  const courseVideos = () => {
    return videoContent?.map(
      (list, idx) =>
        list.visibility &&
        !list.document && (
          <div key={list._id} className={classes.courseDisplay}>
            {list?.completed && !list.document && list.visibility ? (
              <RiCheckboxFill size={18} color="green" />
            ) : !list.document && list.visibility ? (
              <RiPushpin2Fill size={18} />
            ) : null}
            {!list.document && list.visibility ? (
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Typography
                    // variant="subtitle2"
                    component="div"
                    className={
                      list.prerequisiteVideos?.length > 0
                        ? // userCourseVideoProgress?.find(
                          //     (item) => item.videoId == list._id
                          //   )
                          list.prerequisiteVideos.filter((el) => {
                            return !userCourseVideoProgress?.some((f) => {
                              return f.videoId == el._id;
                            });
                          }).length === 0
                          ? classes.courseTitleGreen
                          : classes.courseTitleRed
                        : classes.courseTitleGreen
                    }
                    onClick={() => handleRestrictedVideos(list)}
                  >
                    {list.title}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography align="right">
                    {new Date(1000 * (list.duration ? list.duration : 0))
                      .toISOString()
                      .substr(11, 8)
                      .replace("00:", "")}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </div>
        )
    );
  };

  return (
    <Container maxWidth="xl" className={classes.courseViewContaier}>
      <Typography
        variant="h5"
        align="center"
        component="div"
        style={{ padding: "1%" }}
        // className={classes.title}
      >
        Course: {particularCourse?.title}
      </Typography>

      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          md={openDrawer ? 6 : 11}
          // style={{ width: "100%", height: "calc(100% - 42px)" }}
          className="image"
        >
          {!displayFile ? (
            <Card className={classes.courseCardImageContainer}>
              <CardActionArea>
                <CardMedia
                  className={`${classes.mediaImage}`}
                  image={particularCourse?.courseImage?.link}
                  title={particularCourse?.title}
                />
              </CardActionArea>
            </Card>
          ) : (
            <FileView file={file} />
          )}
        </Grid>
        {/* <Grid
            item
            xs={12}
            md={6}
            style={{ width: "100%", height: "calc(100% - 42px)" }  }
            className="imageContentMobile"
               >
            {!displayFile ? (
              <Card className={classes.courseCardImageContainer}>
                <CardActionArea>
                  <CardMedia
                    className={`${classes.mediaImageMobile}`}
                    image={particularCourse?.courseImage.link}
                    title={particularCourse?.title} 
                   
                  />
                </CardActionArea>
              </Card>
            ) : (
              <FileView file={file} />
            )}
          </Grid>  */}
        <Grid item xs={12} md={openDrawer ? 6 : 1}>
          <AppBar position="static">
            <Toolbar>
              {openDrawer ? (
                <ArrowForwardIcon
                  onClick={() => setOpenDrawer(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Menu
                  onClick={() => setOpenDrawer(true)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Toolbar>
          </AppBar>
          {/* <TabContext value={value}>
            <AppBar position="static">
              <TabList onChange={handleChange} aria-label="simple tabs example">
                <Tab
                  label={`Contents  -  ${new Date(
                    1000 * particularCourse?.duration || 0
                  )
                    .toISOString()
                    .substr(11, 8)}`}
                  value="1"
                />
                <Tab
                  label={`Documents(${
                    documents?.filter(
                      (file) => file?.documentType === "Document"
                    )?.length
                  })`}
                  value="2"
                />
                <Tab
                  label={`Scenarios(${
                    documents?.filter(
                      (file) => file?.documentType === "Scenarios"
                    )?.length
                  })`}
                  value="3"
                />
              </TabList>
              <Toolbar>
                {openDrawer ? (
                  <Close
                    onClick={() => setOpenDrawer(false)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Menu
                    onClick={() => setOpenDrawer(true)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Toolbar>
            </AppBar>

            <TabPanel value="1">
              <div className={classes.scrollable}> {courseVideos()} </div>
            </TabPanel>

            <TabPanel value="2">
              {videoContent &&
              videoContent?.filter((file) => file?.documentType === "Document")
                ?.length > 0 ? (
                videoContent
                  ?.filter((file) => file?.documentType === "Document")
                  ?.map((list, idx) => {
                    // console.log(list.visibility);
                    return (
                      list?.visibility && (
                        <>
                          <Typography
                            // variant="subtitle2"
                            style={{ marginBottom: "5px" }}
                            component="div"
                            className={classes.courseTitleGreen}
                            onClick={() => handleFile(list)}
                          >
                            <RiFile3Fill size={18} /> {""}
                            {list?.document?.name}
                          </Typography>
                        </>
                      )
                    );
                  })
              ) : (
                <Typography
                  // variant="subtitle2"
                  component="div"
                  className={classes.courseTitle}
                >
                  No Documents found
                </Typography>
              )}
            </TabPanel>
            <TabPanel value="3">
              {videoContent &&
              videoContent?.filter((file) => file?.documentType === "Scenarios")
                .length > 0 ? (
                videoContent
                  ?.filter((file) => file?.documentType === "Scenarios")
                  ?.map((list, idx) => (
                    <>
                      <Typography
                        // variant="subtitle2"
                        component="div"
                        className={classes.courseTitleGreen}
                        onClick={() => handleFile(list)}
                      >
                        <RiFile3Fill size={18} /> {""}
                        {list?.document?.name}
                      </Typography>
                    </>
                  ))
              ) : (
                <Typography
                  // variant="subtitle2"
                  component="div"
                  className={classes.courseTitle}
                >
                  No Scenarios found
                </Typography>
              )}
            </TabPanel>
          </TabContext> */}
          <Container
            style={
              openDrawer
                ? { display: "block", padding: "0" }
                : { display: "none" }
            }
          >
            <Accordion>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography className="accordionSummaryHeading">
                      {`Contents  -  ${new Date(
                        1000 * particularCourse?.duration || 0
                      )
                        .toISOString()
                        .substr(11, 8)}`}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={8} md={8}>
                        <Typography className="heading">Total : {learnerInfo?.userDetails?.programsEnrolled?.length}</Typography>
                      </Grid> */}
                </Grid>
                {/* <Typography className="heading">Program Enrolled</Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                {<div className={classes.scrollable}>{courseVideos()}</div>}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography className="accordionSummaryHeading">
                      {`Documents(${
                        documents?.filter(
                          (file) => file?.documentType === "Document"
                        )?.length
                      })`}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              {/* <AccordionDetails> */}
              {videoContent &&
              videoContent?.filter((file) => file?.documentType === "Document")
                ?.length > 0 ? (
                videoContent
                  ?.filter((file) => file?.documentType === "Document")
                  ?.map((list, idx) => {
                    // console.log(list.visibility);
                    return (
                      list?.visibility && (
                        <>
                          <Typography
                            // variant="subtitle2"
                            style={{ marginBottom: "5px" }}
                            component="div"
                            className={classes.courseTitleGreen}
                            onClick={() => handleFile(list)}
                          >
                            <RiFile3Fill size={18} /> {""}
                            {list?.document?.name}
                          </Typography>
                        </>
                      )
                    );
                  })
              ) : (
                <Typography
                  // variant="subtitle2"
                  component="div"
                  className={classes.courseTitle}
                >
                  No Documents found
                </Typography>
              )}
              {/* </AccordionDetails> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography className="accordionSummaryHeading">
                      {`Scenarios(${
                        documents?.filter(
                          (file) => file?.documentType === "Scenarios"
                        )?.length
                      })`}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              {/* <AccordionDetails> */}
              {videoContent &&
              videoContent?.filter((file) => file?.documentType === "Scenarios")
                .length > 0 ? (
                videoContent
                  ?.filter((file) => file?.documentType === "Scenarios")
                  ?.map((list, idx) => (
                    <>
                      <Typography
                        // variant="subtitle2"
                        component="div"
                        className={classes.courseTitleGreen}
                        onClick={() => handleFile(list)}
                      >
                        <RiFile3Fill size={18} /> {""}
                        {list?.document?.name}
                      </Typography>
                    </>
                  ))
              ) : (
                <Typography
                  // variant="subtitle2"
                  component="div"
                  className={classes.courseTitle}
                >
                  No Scenarios found
                </Typography>
              )}
              {/* </AccordionDetails> */}
            </Accordion>
          </Container>
        </Grid>
      </Grid>
      <DialogModal
        title={"Document"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        children={
          <div style={{ width: "1000px", height: "calc(100% - 42px)" }}>
            <FileView file={file} />
            {/* <Typography>Vishwas.V.Bharadwaj</Typography> */}
          </div>
        }
      />
    </Container>
  );
};

CourseView.propTypes = {
  getParticularCourse: PropTypes.func.isRequired,
  getCourseDocuments: PropTypes.func.isRequired,
  getVideosAndDocuments: PropTypes.func.isRequired,
  getVideoProgress: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
  accounts: state.accounts,
});

const mapDispatchToProps = {
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
  getVideoProgress,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseView);
