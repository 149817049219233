import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageHeader from "../../../reusableComponents/PageHeader";
import Tables from "../../MaterialTable/MaterialTable";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import isEmpty from "../../../reusableComponents/is-empty";
import { getAllAdmins } from "../../../redux/actions/AccountsActions";
import DialogModal from "../../../reusableComponents/Modal/DialogModal";
import AddNew from "./AddNew";

const Coach = ({ getAllCoachs, auth, accounts }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const { isAuthenticated } = auth;
  const { accountAdminsList } = accounts;

  useEffect(() => {
    if (isEmpty(accountAdminsList) && isAuthenticated) {
        getAllCoachs();
    }
  }, [accountAdminsList, isAuthenticated]);

  const columns = [
    {
      title: "First Name",
      field: "firstName",
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "User Name",
      field: "userName",
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      //color: "#3d4977",
      color: "white",
    },
    scrollable: true,
    pageSize: 7,
    searchFieldStyle: {
      width: "100%",
    },
  };

  return (
    <>
      <PageHeader
        title="Coach's List"
        subTitle=" all coach's list"
        icon={<PeopleOutlineIcon fontSize="large" />}
      />

      <Tables
        title="Coach List"
        columns={columns}
        data={accountAdminsList}
        searchField
        addNewText="Create New Coach"
        addOnClick={() => setOpenPopup(true)}
        selectedRow={(list) => console.log("")}
        options={options}
      />

      <DialogModal
        title="Create New Coach"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddNew type="coach" />
      </DialogModal>
    </>
  );
};

Coach.propTypes = {
  getAllCoachs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
});

const mapDispatchToProps = {getAllAdmins};

export default connect(mapStateToProps, mapDispatchToProps)(Coach);
