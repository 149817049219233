import React from 'react';
import PageHeader from '../../../reusableComponents/PageHeader';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles, Paper,Container } from '@material-ui/core';
import NewCourseForm from './NewCourseForm'; 


const useStyles = makeStyles(theme => ({ 

    header:{
        margin: theme.spacing(0, 0),
        padding: theme.spacing(1)
    },
    pageContent: {
        margin: theme.spacing(0, 0),
        padding: theme.spacing(0)
    } ,
    page:{
        padding: theme.spacing(3)
    } 
  
  })) 


const NewCourse = () => {
    const classes = useStyles();
    return (
        <> 
         <Container maxWidth="lg" className={classes.header}> 
          <Paper className={classes.pageContent}>
            <PageHeader 
                title="Add New Course"
                subTitle="Enter new course details"
                icon={<AddBoxIcon fontSize="large" />}
            /> 
            </Paper>
            <Paper className={classes.page}>
                <NewCourseForm />
            </Paper> 
            </Container>
        </>
    );
};

export default NewCourse;