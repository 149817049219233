import axios from "axios";
import {
  GET_ACCOUNT_USERS_LOADING,
  GET_ACCOUNT_USERS_LIST,
  GET_ACCOUNT_USER_PROFILE,
  GET_ACCOUNT_ADMINS_LIST,
  GET_ACCESS_LOG_LIST,
  GET_USER_COURSE_VIDEO_PROGRESS, 
  //GET_ACCOUNT_COACH_LIST,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";

// Get all users
export const getAllUsers = () => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .get("/api/user/usersList/user")
    .then((res) =>
      dispatch({
        type: GET_ACCOUNT_USERS_LIST,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get all admins
export const getAllAdmins = () => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .get("/api/user/usersList/admin")
    .then((res) =>
      dispatch({
        type: GET_ACCOUNT_ADMINS_LIST,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
}; 
// Get all Coach
// export const getAllCoachs = () => (dispatch) => {
//   dispatch(getAccountUsersLoading());
//   axios
//     .get("/api/user/usersList/coach")
//     .then((res) =>
//       dispatch({
//         type: GET_ACCOUNT_COACH_LIST,
//         payload: res?.data?.message,
//       })
//     )
//     .catch((err) => {
//       const { message } = err?.response?.data;
//       SnackBar.error(message);
//     });
// };
 

// Get user Profile
export const getUserProfile = (userId) => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .get(`/api/user/profile/${userId}`)
    .then((res) =>
      dispatch({
        type: GET_ACCOUNT_USER_PROFILE,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get all accesslog
export const getAccessLog = () => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .get("/api/category/allusers")
    .then((res) =>
      // console.log(res.data)
      dispatch({
        type: GET_ACCESS_LOG_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Search Accesslog
export const searchAccessLog = (data) => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .post("/api/category/accessLog", data)
    .then((res) =>
      // console.log(res.data)
      dispatch({
        type: GET_ACCESS_LOG_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get User video Progress
export const getVideoProgress = (data) => (dispatch) => {
  dispatch(getAccountUsersLoading());
  axios
    .post("/api/category/getVideosProgress", data)
    .then((res) =>
      // console.log(res.data)
      dispatch({
        type: GET_USER_COURSE_VIDEO_PROGRESS,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      console.log(message);
      //   SnackBar.error(message);
    });
};

// Get Account users Loading
export const getAccountUsersLoading = () => {
  return {
    type: GET_ACCOUNT_USERS_LOADING,
  };
};
