import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContainer: {
    // height: 290,
    // width: 300,
    padding: "0%",
    marginTop: "8%",
    width: "90%",
    height: "350px ",
    borderRadius: "20px",
    border: "1px solid #003399",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow:
        " 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06)",
    },
  },

  media: {
    height: 180,
    width: "100%",
  },
  titleContainer: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: "#003399",
  },
  button: {
    width: 220,
    align: "center",
    margin: "5px",
    marginBottom: "20px",
    //marginRight: "30px",
    //background: "#048fd2",
    color: "#003399",
    background: "white",
    "&:hover": {
      background: "#5a87e2",
      color: "white",
    },
  },
  button1: {
    width: 250,
    margin: "5px",
    //marginTop:"40px",
    //background: "#048fd2",
    color: "#003399",
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  slider: {
    padding: theme.spacing(1),
    marginLeft: 30,
    width: 230,
  },
  icon: {
    marginLeft: 30,
    fontSize: "1rem",
  },
  percentage: {
    marginLeft: 90,
  },
}));

export default function CourseCard({ data, onClick, allCourses }) {
  const classes = useStyles();
  const { title, courseImage, percentage } = data;

  return (
    <Card className={classes.cardContainer} onClick={onClick}>
      <CardActionArea>
        <CardContent className={classes.titleContainer}>
          <Typography
            className={classes.title}
            variant="h6"
            component="div"
            align="center"
          >
            {title}
          </Typography>
        </CardContent>
        <CardMedia
          className={`${classes.media} mediaImage`}
          image={courseImage?.link}
          title={title}
        />
      </CardActionArea>

      <Grid container spacing={2} align="center" className="container">
        {!allCourses && (
          <Grid item xs align="center">
            <LinearProgress
              value={parseInt(percentage) > 100 ? 100 : parseInt(percentage)}
              align="center"
              className="progressBar"
              style={{ width: "100%" }}
              variant="determinate"
            />
            <Typography
              style={{ color: "white" }}
              variant="h7"
              component="div"
              align="center"
            >
              {percentage}%
            </Typography>
          </Grid>
        )}
        <CardActions>
          <Button
            className={!allCourses ? classes.button : classes.button1}
            variant="contained"
          >
            {!allCourses ? <>START COURSE</> : <>VIEW COURSE</>}
          </Button>
        </CardActions>
      </Grid>
    </Card>
  );
}
