import axios from "axios";
import { ADD_NEW_PROGRAM, GET_PROGRAM_LIST, PROGRAMS_LOADING } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";
import { getAssignedCourses } from "./CoursesActions";

// Get All Programs
export const getAllPrograms = () => (dispatch) => {
  dispatch(programLoading());
  axios
    .get("/api/program/getPrograms")
    .then((res) =>
      dispatch({
        type: GET_PROGRAM_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Add New Program
export const addNewProgram = (data) => (dispatch) => {
  dispatch(programLoading());
  axios
    .post("/api/program/addPrograms", data)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      history.push(`/admin/programs`);
      dispatch(getAllPrograms());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Assign Program to user
export const assignProgram = (data) => (dispatch) => {
  dispatch(programLoading());
  axios
    .put("/api/program/assignProgram", data)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      dispatch(getAssignedCourses(data.userId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Programs Loading
export const programLoading = () => {
  return {
    type: PROGRAMS_LOADING,
  };
};
