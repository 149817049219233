import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Avatar,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginUser } from "../../redux/actions/authActions";
import * as Yup from "yup";
import ButtonField from "../../reusableComponents/ButtonField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const SignIn = ({ loginUser, auth, history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, user } = auth;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Username / Email is required field"),
      password: Yup.string().required("Password is required field"),
    }),
    onSubmit: async (values) => {
      await loginUser(values);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user?.role?.r_id === 1 ||
        user?.role?.r_id === 3 ||
        user?.role?.r_id === 4
      ) {
        history.push("/admin/dashboard");
      } else {
        history.push("/courses");
      }
    }
  }, [auth]);

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#003399",
    color: "white",
  };

  return (
    <div className="main">
      {/* <form onSubmit={(e) => this.handleSubmit(e)}> */}
      <section className="login">
        <Grid container className="SigninIC">
          <Grid item className="LeftBox" md={6}>
            <p className="LeftBoxHead">Welcome To iLearn App</p>
            <ul>
              {/* <li>  */}

              <p className="LeftBoxSubHead-1">
                i-learn is a platform for students by learning there courses
                offline by listening and seeing.
              </p>

              {/* </li>  */}
              {/* <li> */}
              <p className="LeftBoxSubHead-2">
                While the assignments make the training more interactive ,
                quizzes help to fine tune your knowledge.
              </p>
              {/* </li> */}
            </ul>
          </Grid>
          <Grid item className="RightBox" xs={12} md={6} align="center">
            <p className="RightHeader1">Sign In</p>
            <div className="form-group login-form-data1 mx-auto">
              <div className=" ">
                <div className="input-group-append">
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <TextFieldGroup
                      label="Username / Email"
                      type="text"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      errors={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      }
                    />
                    <TextFieldGroup
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      passwordControl={() => setShowPassword(!showPassword)}
                      showPassword={showPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      errors={
                        formik.touched.password && formik.errors.password
                          ? formik.errors.password
                          : null
                      }
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      style={btnstyle}
                      fullWidth
                    >
                      Login{" "}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
            <p className="forgotPasswordContainer">
              Or{" "}
              <Typography variant="subtitle2">
                <Link className="forgotPassword" to="forgot-Password">
                  Forgot Password ?
                </Link>
              </Typography>
            </p>
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

SignIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
