import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllPrograms } from "../../../redux/actions/programActions";
import Table from "../../MaterialTable/Table";
import PageHeader from "../../../reusableComponents/PageHeader";
import ListAltIcon from "@material-ui/icons/ListAlt";
import isEmpty from "../../../reusableComponents/is-empty";

const Courses = ({ getAllPrograms, programs, auth, history }) => {
  const { isAuthenticated } = auth;
  const { programList } = programs;

  useEffect(() => {
    if (isEmpty(programList) && isAuthenticated) {
      getAllPrograms();
    }
  }, [programList, isAuthenticated]);

  const columns = [
    {
      title: "Image",
      field: "image",
      disableSorting: true,
      cellStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerStyle: {
        width: 100,
        maxWidth: 100,
      },
      render: (rowData) => (
        <img style={{ height: 50 }} src={rowData.image} alt="" />
      ),
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Description",
      field: "description",
    },
  ];

  const data = programList?.map((list) => {
    const { _id, title, programImage, description } = list;
    return {
      _id: _id,
      name: title,
      image: programImage?.link,
      description: description,
    };
  });

  return (
    <>
      <PageHeader
        title="Program List"
        subTitle="List of all Programs"
        icon={<ListAltIcon fontSize="large" />}
      />
      <Table
        title="Program List"
        columns={columns}
        data={data}
        searchField
        addNewText="Add New Program"
        addOnClick={() => history.push("/admin/newProgram")}
        onCellClick={
          (course) => console.log("clicked")
          // history.push(`/admin/course/${course._id}`)
        }
      />
    </>
  );
};

Courses.propTypes = {
  getAllPrograms: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = { getAllPrograms };

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
