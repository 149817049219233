import axios from "axios";
import setAuthToken from "../../reusableComponents/SetAuthToken";
import { SET_CURRENT_LOADING, SET_CURRENT_USER } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// register User
export const registerUser = (userData) => (dispatch) => {
  axios
    .post("/api/user/register", userData)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      window.location.reload();
    })
    .catch((err) => {
      const { email, userName, firstName } = err?.response?.data;
      if (email) {
        SnackBar.error(email);
      }
      if (userName) {
        SnackBar.error(userName);
      }
      if (firstName) {
        SnackBar.error(firstName);
      }
    });
};

// Login User
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/user/login", userData)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iLearnOnline", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit User Profile
export const editUser = (userData) => (dispatch) => {
  axios
    .put("/api/user/editProfile", userData)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      dispatch(setCurrentUser());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit User Password
export const passswordReset = (userData) => (dispatch) => {
  axios
    .put("/api/user/profilePassword", userData)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      dispatch(logoutUser());
      history.push("/sign-in");
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Current User
export const setCurrentUser = () => (dispatch) => {
  axios
    .get("/api/user/profile")
    .then((res) =>
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      if (message) {
        SnackBar.error(message);
      }
      dispatch(logoutUser(history));
    });
};

// Forgot Password
export const forgotPassword = (userData) => (dispatch) => {
  axios
    .post("/api/user/forgotPassword", userData)
    .then((res) => {
      const { message } = res?.data;
      history.push("/sign-In");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Reset Password
export const resetPassword = (userPassword) => (dispatch) => {
  axios
    .post(`/api/user/resetPassword/${userPassword.token}`, userPassword)
    .then((res) => {
      const { message } = res?.data;
      history.push("/sign-In");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      if (message.message === "jwt expired") {
        history.push("/forgot-Password");
        SnackBar.error(
          "Token Expired, Please enter your email id to verify again"
        );
      } else {
        SnackBar.error(message);
      }
    });
};

// Post website access log
export const postAccessLog = (data) => (dispatch) => {
  axios
    .post("/api/user/postAccessLog", data)
    .then((res) => {})
    .catch((err) => {
      const { message } = err?.response?.data;
      console.log(message);
    });
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from the localstorage
  localStorage.removeItem("iLearnOnline");
  // Remove auth headers from future requests
  history.push("/");
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  // dispatch(setCurrentUser({}));
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};
