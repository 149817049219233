import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import { registerUser } from "../../../redux/actions/authActions";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "flex-end",
  },
  formSubmit: {
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    //background: '#3d4977',
    background: "#003399",
    color: "#F8F9F9",
    "&:hover": {
      background: "#003399",
    },
  },
}));

const AddNew = ({ registerUser, type }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Firstname is required")
        .trim()
        .min(4, "FirstName must be at least 4 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      lastName: Yup.string()
        .trim()
        .required("LastName is required")
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only alphabets"),
      userName: Yup.string()
        .trim()
        .required("UserName is required")
        .min(4, "UserName must be at least 4 characters"),
      email: Yup.string()
        .trim()
        .required("Email is required")
        .email("Email is Invalid"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          "(?=.*[a-z])",
          "Password must contain at least 1 Lower Case character"
        )
        .matches(
          "(?=.*[A-Z])",
          "Password must contain at least 1 Upper Case character"
        )
        .matches("(?=.*[0-9])", "Password must contain at least 1 Number")
        .matches(
          "(?=.*[!@#$%^&*])",
          "Password must contain at least 1 special character"
        )
        .required("Password is required field"),
      confirmPassword: Yup.string()
        .trim()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      let userData = values;
      if (type === "admin") {
        userData.role = type;
      }
      await registerUser(userData);
    },
  });

  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextFieldGroup
            label="First Name"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            errors={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />

          <TextFieldGroup
            label="Username"
            type="text"
            name="userName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.userName}
            errors={
              formik.touched.userName && formik.errors.userName
                ? formik.errors.userName
                : null
            }
          />
          <TextFieldGroup
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            passwordControl={() => setShowPassword(!showPassword)}
            showPassword={showPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGroup
            label="Last Name"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            errors={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />
          <TextFieldGroup
            label="Email"
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />

          <TextFieldGroup
            label="Confirm Password"
            type={confirmShowPassword ? "text" : "password"}
            name="confirmPassword"
            passwordControl={() => setConfirmShowPassword(!confirmShowPassword)}
            showPassword={confirmShowPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            errors={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : null
            }
          />
          <div className={classes.buttonContainer}>
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="Create"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

AddNew.propTypes = {
  registerUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = { registerUser };

export default connect(null, mapDispatchToProps)(AddNew);
