import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types"; 
import { Container,Paper } from "@material-ui/core"; 
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../../reusableComponents/PageHeader";
import { GroupAdd as GroupAddIcon} from "@material-ui/icons";
import isEmpty from "../../../reusableComponents/is-empty";
import { getAllUsers } from "../../../redux/actions/AccountsActions";
import DialogModal from "../../../reusableComponents/Modal/DialogModal";
import AddNew from "./AddNew";
import Tables from "../../MaterialTable/MaterialTable"; 


const useStyles = makeStyles(theme => ({
  pageContent: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(0),
  },
}))


const Users = ({ getAllUsers, auth, accounts, history }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const { isAuthenticated } = auth;
  const { accountUsersList } = accounts;

  useEffect(() => {
    if (isEmpty(accountUsersList) && isAuthenticated) {
      getAllUsers();
    }
  }, [accountUsersList, isAuthenticated]);

  const columns = [
    {
      title: "First Name",
      field: "firstName",
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "User Name",
      field: "userName",
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      //color: "#3d4977",
      color: "white",
    },
    scrollable: true,
    pageSize: 7,
    searchFieldStyle: {
      width: "100%",
    },
  };
  const classes = useStyles();
  return (
    <> 
     <Container maxWidth="lg" > 
     <Paper className={classes.pageContent}>
      <PageHeader
        title="Users List"
        subTitle="All users list"
        icon={<GroupAddIcon fontSize="large" />}
      />  
        </Paper>
    
      {/* <DialogModal
        title="Create New User"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddNew type="user" />
      </DialogModal> */}

      <Tables
        title="Users List"
        columns={columns}
        data={accountUsersList}
        searchField
        //addNewText="Create new User"
        addOnClick={() => setOpenPopup(true)}
        selectedRow={(list) => history.push(`/admin/user/${list._id}`)}
        options={options}
      /> 
        </Container> 

    </>
  );
};

Users.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
});
const mapDispatchToProps = { getAllUsers };

export default connect(mapStateToProps, mapDispatchToProps)(Users);
