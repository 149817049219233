import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PersonIcon from "@material-ui/icons/Person";
import AppsIcon from "@material-ui/icons/Apps";
import CreateIcon from "@material-ui/icons/Create";
import Logo from "../../../images/ibi.png";
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  GroupAdd,
  AddBox,
  SupervisorAccount,
  AddCircle,
  LocalLibrary,
  LibraryBooksRounded,
} from "@material-ui/icons";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountCircle,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  ListAlt as ListAltIcon,
  AddBox as AddBoxIcon,
  LibraryBooksRounded as LibraryBooksRoundedIcon,
  FileCopy as FileCopyIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { logoutUser } from "../../../redux/actions/authActions";
//import { registerUser } from "../../../redux/actions/authActions";
import { resetPassword } from "../../../redux/actions/authActions";
import DrawerList from "./DrawerList.json";
import isEmpty from "../../../reusableComponents/is-empty.js";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex ",
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    minWidth: 35,
    color: "white",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    //background: "#008dd2",
    backgroundColor: "White",
    zIndex: theme.zIndex.drawer + 1,
    //background: "#fff",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    //color: "#233044",
    fontWeight: 600,
    letterSpacing: 1,
    color: "black",
  },
  menuButton: {
    color: "#2b2b2b",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: "#003399",
    color: "white",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  profileIconButton: {
    border: "1px solid  #003399",
    //border:"1px soild #ffffff",
    borderRadius: 25,
    color: " #003399",
    //color: "#ffffff",
    //backgroundColor:"#003399",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid  #003399",
      color: " #003399",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
    //backgroundColor:"#003399"
  },
  userDetails: {
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
  },
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      //background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // background:"white",
      //color: '#F8F9F9',
      //color:"black",
      "&:hover": {
        //background: '#1e293a'
        //backgroundColor:"white"
      },
    },
    "& .MuiSvgIcon-root": {
      // color:"white",
      // marginRight: theme.spacing(1),
    },
  },
}));
function AdminLayout(props) {
  {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [nestOpen, setNestOpen] = useState(false);

    // const { user } = auth;

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    // const handleDrawerOpen = () => {
    //   setOpen(false);
    // };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleDrawerToggle() {
      setOpen(!open);
    }
    const handleNestClick = () => {
      setNestOpen(!nestOpen);
    };
    const { pathname } = props.location;
    const { isAuthenticated, user } = props.auth;
    const drawer = (
      <div>
        <div className={`${classes.drawerHeader}`}>
          {/* Administration         */}
        </div>
        <List>
          {DrawerList.map((list, i) => {
            const { name, route, nested } = list;
            if (!isEmpty(nested)) {
              return (
                <>
                  <ListItem
                    button
                    key={i}
                    selected={pathname === route}
                    onClick={handleNestClick}
                  >
                    <ListItemIcon classes={{ root: classes.icon }}>
                      {list.icon === "GroupAdd" ? <GroupAdd /> : null}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{ style: { color: "white" } }}
                    />
                    {nestOpen ? (
                      <ExpandLess className="expIcon" />
                    ) : (
                      <ExpandMore className="expIcon" />
                    )}
                  </ListItem>
                  <Collapse in={nestOpen} timeout="auto" unmountOnExit>
                    {nested.map((nest, i) => (
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          key={i}
                          className={classes.nested}
                          selected={pathname === nest.route}
                          onClick={() => {
                            props.history.push(nest.route);
                          }}
                        >
                          <ListItemIcon classes={{ root: classes.icon }}>
                            {nest.icon === "SupervisorAccount" ? (
                              <SupervisorAccount />
                            ) : nest.icon === "AccountCircle" ? (
                              <AccountCircle />
                            ) : nest.icon === "LibraryBooksRoundedIcon" ? (
                              <LibraryBooksRoundedIcon />
                            ) : nest.icon === "AppsIcon" ? (
                              <FileCopyIcon />
                            ) : nest.icon === "AppsIcon" ? (
                              <AppsIcon />
                            ) : nest.icon === "CreateIcon" ? (
                              <CreateIcon />
                            ) : null}
                          </ListItemIcon>
                          <ListItemText
                            primary={nest.name}
                            primaryTypographyProps={{
                              style: { color: "white" },
                            }}
                          />
                        </ListItem>
                      </List>
                    ))}
                  </Collapse>
                </>
              );
            } else {
              return (
                <ListItem
                  button
                  key={i}
                  selected={pathname === route}
                  onClick={() => {
                    props.history.push(route);
                  }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    {list.icon === "Dashboard" ? (
                      <DashboardIcon />
                    ) : list.icon === "AddBox" ? (
                      <AddBox />
                    ) : list.icon === "AddCircle" ? (
                      <AddCircle />
                    ) : list.icon === "LocalLibrary" ? (
                      <LocalLibrary />
                    ) : list.icon === "FileCopyIcon" ? (
                      <FileCopyIcon />
                    ) : list.icon === "AppsIcon" ? (
                      <AppsIcon />
                    ) : list.icon === "CreateIcon" ? (
                      <CreateIcon />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    primaryTypographyProps={{ style: { color: "white" } }}
                  />
                </ListItem>
              );
            }
          })}
        </List>
      </div>
    );
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.title}>
              {/* I Learn Online - Administration */}
              {/*i Learn - Administration*/}
              <img
                src={Logo}
                style={{
                  width: "205px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
            </Typography>
            <div className={classes.grow} />
            <div className={classes.userDetails}>
              <Button
                disableRipple
                className={classes.profileIconButton}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleMenu}
              >
                <AccountCircle className={classes.profileIcon} />{" "}
                {user?.firstName}{" "}
                {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              <Menu
                className={classes.profileDrop}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    props.history.push("/admin/editProfile");
                    handleClose();
                  }}
                >
                  <PersonIcon /> EditProfile
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    props.history.push("/admin/passwordReset");
                  }}
                >
                  <VpnKeyIcon /> ResetPassword
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    props.logoutUser();
                    handleClose();
                  }}
                >
                  <ExitToAppIcon /> Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={open}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <IconButton
                onClick={handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <div className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </div>
      </div>
    );
  }
}
AdminLayout.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { logoutUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminLayout));
