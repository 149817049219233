import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import {
  getAllPrograms,
  assignProgram,
} from "../../../redux/actions/programActions";
import isEmpty from "../../../reusableComponents/is-empty";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import ButtonField from "../../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  btnSubmit: {
    backgroundColor: "#003399",
    color: "#F8F9F9",
    opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      opacity: "1",
    },
  },
}));

const AssignProgram = ({
  userId,
  auth,
  getAllPrograms,
  assignProgram,
  programs,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      assignProgram: {},
    },
    validationSchema: Yup.object({
      assignProgram: Yup.object()
        .shape({
          _id: Yup.string().trim(),
          title: Yup.string().trim(),
          createdAt: Yup.string().trim(),
          updatedAt: Yup.string().trim(),
        })
        .required("Course is required field to assign"),
    }),
    onSubmit: async (values) => {
      if (!isEmpty(values?.assignProgram)) {
        const newProgram = {
          programId: values?.assignProgram?._id,
          userId,
        };
        console.log(newProgram);
        await assignProgram(newProgram);
      }
    },
  });

  const { isAuthenticated } = auth;
  const { programList } = programs;

  useEffect(() => {
    if (isEmpty(programList) && isAuthenticated) {
      getAllPrograms();
    }
  }, [programList, isAuthenticated]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Assign Program"
              name="assignProgram"
              favourite={programList}
              onSelect={(program) =>
                formik.setFieldValue("assignProgram", program)
              }
              // selected={formik.values.assignCourse}
              errors={
                formik.touched.assignProgram && formik.errors.assignProgram
                  ? formik.errors.assignProgram
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Assign Program"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

AssignProgram.propTypes = {
  getAllPrograms: PropTypes.func.isRequired,
  assignProgram: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = { getAllPrograms, assignProgram };

export default connect(mapStateToProps, mapDispatchToProps)(AssignProgram);
