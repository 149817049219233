import axios from "axios";
import setAuthToken from "../../reusableComponents/SetAuthToken";
import {
  SET_CURRENT_LOADING,
  SET_CURRENT_USER,
  IBRIDGE_COURSE_CONTENT,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";
import { setCurrentUser } from "./authActions";

// Get Ibridge User
export const checkIbridgeUser = (data) => (dispatch) => {
  axios
    .get(`/api/ibridge/user/${data}`)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iLearnOnline", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
      dispatch({
        type: IBRIDGE_COURSE_CONTENT,
        payload: res?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Redirect from ibridge to course content
export const redirectToCourseContent = (data) => (dispatch) => {
  axios
    .post(`/api/ibridge/checkIbridgeCourses`, data)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iLearnOnline", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
      dispatch({
        type: IBRIDGE_COURSE_CONTENT,
        payload: res?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};
