import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { BsExclamationDiamond } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      margin: theme.spacing(6),
    },
  },
}));

const SelectSingleValues = ({
  showLable,
  heading,
  placeholder,
  errors,
  refs,
  name,
  className,
  selected,
  onSelect,
  size,
  favourite,
  label,
}) => {
  const classes = useStyles();

  const errorText = (
    <p>
      <BsExclamationDiamond /> {errors}
    </p>
  );

  return (
    <div className={classes.root}>
      {showLable && <div className="fieldHeading">{heading}</div>}
      <Autocomplete
        id="tags-outlined"
        options={favourite}
        getOptionLabel={(option) => option.title}
        defaultValue={selected}
        value={selected}
        onChange={(event, value) => {
          onSelect(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            label={label}
            variant="outlined"
            inputRef={refs}
            size={size || "small"}
            id={name}
            name={name}
            error={errors ? true : false}
            helperText={errors ? errorText : null}
          />
        )}
      />
    </div>
  );
};

export default SelectSingleValues;
