import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import CardActions from '@material-ui/core/CardActions';
import image1 from "../../images/image1.png";
import landingimage from "../../images/landingimage.png";

const useStyles = makeStyles({
  root: {
    minWidth: 1090,
    minHeight: 350,
    backgroundColor: "#008dd2",
    borderColor: "white",
    borderWidth: "3px",
  },
  title: {
    fontSize: 25,
    color: "white",
  },
  text: {
    padding: "10px",
    marginTop: 17,
    color: "white",
  },
  line: {
    marginTop: "20px ",
    height: "3px",
    backgroundColor: "white",
    marginRight: "0px",
    width: "100%",
  },
  cardcolor: {
    //backgroundColor: "#89CFF0", 
    backgroundColor:"#003399"
  },
});

const Welcome = ({ auth, history }) => {
  const { isAuthenticated, user } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user?.role?.r_id === 1 ||
        user?.role?.r_id === 3 ||
        user?.role?.r_id === 4
      ) {
        history.push("/admin/dashboard");
      } else {
        history.push("/courses");
      }
    }
  }, [auth]);

  const classes = useStyles();

  return (
    <Container maxWidth="lg" className="welcomeContainer">
      <Grid container>
        <Grid item xs={12} className="welcomeTopContainer">
          <div className="startImageContainer">
            <img src={landingimage} className="startImage" />
            <div className="textImage">
              <div>
                <Typography className="headingImageText" varient="h5">
                  Why I Learn
                </Typography>
                <Typography className="headingImageTagLine" varient="h3">
                  ‘I Learn ’ is an online database and data warehousing training
                  website designed for corporate employees, individuals and job
                  seekers.All the training sessions are based on scenarios as it
                  is a proven methodology. <br />
                  While the assignments make the training more interactive,
                  quizzes help to fine tune your knowledge.
                </Typography>
              </div>
            </div>
          </div>
          <Typography className="headingText" variant="h5">
            I Learn
          </Typography>
          <Typography className="headingTagLine" variant="h6">
            E-learning portal for database and data warehousing technologies
          </Typography>
        </Grid>
        <Grid item xs={12} className="welcomeStartImageContainer">
          <img src={image1} className="welcomeStartImage dimensions" />
        </Grid>
        <Grid item xs={12} className="welcomeOnlineEducation">
          <Typography className="headingTexts" variant="h4">
            Need for online education
          </Typography>
          <Typography className="headingTagLines" variant="h6">
            As an individual or a company its very important to access the
            information online now a days to make you more productive.
            E-learning makes it more efficient to learn only the things you
            needed rather than sitting in the class and listen to what you
            already known.
          </Typography>
          <Typography className="headingTagLines" variant="h6">
            What we teach in the class room session, we capture the same using
            Audio and video. Any questions you have in the material or examples
            what we provide, you can send us an email or call us to get the
            questions clarified.
          </Typography>
        </Grid>
        <Grid item xs={12} className="welcomeTestimonials">
          <Card className={classes.cardcolor}>
            <CardContent>
              <Typography className="headingTexts1" variant="h5">
                Testimonials
              </Typography>
              <Typography className="headingLine" variant="h6">
                "The methodological approach is fantastic backed with a robust
                tools combination. I would recommend Aroha Technologies to
                anyone. It’s quite useful in getting a job. Aroha has proved to
                be of tremendous contribution in my current M.Sc programme in
                Management Information Systems at Coventry University."
              </Typography>
              <Typography className="headingTagLine" variant="h6">
                Yemi Jide
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

Welcome.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Welcome);
