import React from 'react';  
import PageHeader from '../../../reusableComponents/PageHeader';  
import { makeStyles, Paper ,Container} from '@material-ui/core';  
import FileCopyIcon from '@material-ui/icons/FileCopy'; 
import DocumentForm from './DocumentForm';

const useStyles = makeStyles(theme => ({ 

    header:{
        margin: theme.spacing(0, 0),
        padding: theme.spacing(1)
    },
    pageContent: {
        margin: theme.spacing(0, 0),
        padding: theme.spacing(0)
    } , 
    page:{
        padding: theme.spacing(3)
    }

}))

const Documents = () => {
    const classes = useStyles();
    return (
        <> 
          <Container maxWidth="lg" className={classes.header}> 
          <Paper className={classes.pageContent}>
            <PageHeader 
                title="Add Documents"
                subTitle="Add Course Documents"
                icon={<FileCopyIcon fontSize="large" />}
            />  
            </Paper>
           
            <Paper className={classes.page}>
               <DocumentForm/>
            </Paper> 
            </Container>
        </>
    );
}; 

export default Documents;