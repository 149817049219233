import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  redirectToCourseContent,
  checkIbridgeUser,
} from "../../redux/actions/iBridgeActions";
import Spinner from "../../reusableComponents/Spinner";

const IbridgeLanding = ({
  redirectToCourseContent,
  checkIbridgeUser,
  auth,
  history,
  match,
  ibridge,
}) => {
  const { ilearn, courseId } = match.params;
  const { isAuthenticated, user } = auth;
  const { ibridgeCourses } = ibridge;
  useEffect(() => {
    // checkIbridgeUser(ilearn);
    if (courseId === "program") {
      checkIbridgeUser(ilearn);
    } else if(courseId === "admin") {
      checkIbridgeUser(ilearn)
    }
    else {
      const data = {
        ilearn,
        courseId,
      };
      redirectToCourseContent(data);
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      // console.log("ibridgeCourses",ibridgeCourses);
      if (ibridgeCourses) {
        if (!ibridgeCourses?.courseId) {
          history.push(`/courses`);
        } else if (user?.assignedPrograms?.find((item) => item === courseId)) {
          history.push(`/course/${ibridgeCourses?.courseId}`);
        }
        if (ibridgeCourses.courseId) {
          history.push(`/course/${ibridgeCourses?.courseId}`);
        }
      }
      else if(courseId === "admin"){
        history.push(`/admin/dashboard`)
      } 
    }
  }, [auth, ibridgeCourses]);

  return (
    <Container maxWidth="lg" className="signInContainer">
      <Typography variant="h4" align="center">
        <Spinner />
        Please wait....
      </Typography>
    </Container>
  );
};

IbridgeLanding.propTypes = {
  redirectToCourseContent: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ibridge: state.ibridge,
});

const mapDispatchToProps = { redirectToCourseContent, checkIbridgeUser };

export default connect(mapStateToProps, mapDispatchToProps)(IbridgeLanding);
