import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import PageHeader from "../../reusableComponents/PageHeader";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { passswordReset } from "../../redux/actions/authActions";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(4, 0),
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "20px",
  },
  formSubmit: {
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    background: "#003399",
    color: "#F8F9F9",
    "&:hover": {
      background: "#003399",
    },
    marginRight: "180px",
  },
}));

const PasswordReset = ({ auth, passswordReset }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { user } = auth;
  //console.log(user.firstName);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password field is required field")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/i,
          "min 6 char with 1 lower, upper, number & symbol"
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password field is required field")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const userPassword = {
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
        userId: user?._id,
      };
      await passswordReset(userPassword);
    },
  });

  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <PageHeader
        title="ResetPassword"
        //subTitle="Edit Profile"
        icon={<LockOpenIcon fontSize="large" />}
      />

      <Grid container spacing={2} align="center" justify="center">
        <Grid item xs={4}>
          <TextFieldGroup
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            name="password"
            passwordControl={() => setShowNewPassword(!showNewPassword)}
            showPassword={showNewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
          <TextFieldGroup
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            passwordControl={() => setShowConfirmPassword(!showConfirmPassword)}
            showPassword={showConfirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            errors={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : null
            }
          />
          <Grid container direction="column">
            <div className={classes.buttonContainer}>
              <ButtonField
                buttonStyle={classes.formSubmit}
                type="submit"
                name="Submit"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

PasswordReset.propTypes = {
  passswordReset: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { passswordReset };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
