import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CourseCard from "../../reusableComponents/CourseCard";
import { getAllCourses } from "../../redux/actions/CoursesActions";
import isEmpty from "../../reusableComponents/is-empty";
import { postAccessLog } from "../../redux/actions/authActions";

const useStyles = makeStyles((theme) => ({
  courseContaier: {
    margin: theme.spacing(3, 0),
  },
}));

const AllCourses = ({
  getAllCourses,
  postAccessLog,
  auth,
  courses,
  history,
}) => {
  const classes = useStyles();

  const { isAuthenticated, user } = auth;
  const { coursesList, filteredCourseList } = courses;
  const [timeInterval, setTimeInterval] = useState(0);
  let tabVisibility = document.visibilityState;
  useEffect(() => {
    if (isEmpty(coursesList)) {
      getAllCourses();
    }
  }, [coursesList]);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "Explore Courses",
        recordedDate: new Date(),
      };
      postAccessLog(data);
    }
  }, [timeInterval, user?._id]);

  const returnAssignedCourses = () => {
    return filteredCourseList?.map((list) => (
      <Grid key={list._id} item xs={12} md={6} lg={3} spacing={2}>
        <CourseCard
          data={list}
          onClick={() => history.push(`/course-view/${list._id}`)}
          allCourses={true}
          // onClick={() => console.log("clicked")}
        />
      </Grid>
    ));
  };

  return (
    <Container maxWidth="xl" className={classes.courseContaier}>
      <Grid container spacing={5}>
        {returnAssignedCourses()}
      </Grid>
    </Container>
  );
};

AllCourses.propTypes = {
  getAllCourses: PropTypes.func.isRequired,
  postAccessLog: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = { getAllCourses, postAccessLog };

export default connect(mapStateToProps, mapDispatchToProps)(AllCourses);
