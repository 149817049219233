import axios from "axios";
import {
  COURSES_LOADING,
  GET_ALL_COURSES,
  GET_ALL_COURSES_LIST,
  GET_ASSIGNED_COURSES_LIST,
  GET_PARTICULAR_COURSES_LIST,
  GET_PARTICULAR_COURSE_VIDEO_INFO,
  UPLOAD_COURSE_IMAGE,
  UPLOAD_FILE_PROCESS,
  UPLOAD_COURSE_DOCUMENTS,
  GET_COURSE_DOCUMENTS,
  GET_PARTICULAR_COURSES_FULL_LIST,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// Get All Courses
export const getAllCourses = () => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get("/api/category/courses")
    .then((res) =>
      dispatch({
        type: GET_ALL_COURSES,
        payload: {
          coursesList: res?.data?.courses,
          filteredCourseList: res?.data?.courses,
        },
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get All Courses List
export const getAllCoursesList = () => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get("/api/category/coursesList")
    .then((res) => {
      dispatch({
        type: GET_ALL_COURSES_LIST,
        payload: res?.data?.courses,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Create New Course
export const createNewCourse = (newCourse) => (dispatch) => {
  axios
    .post("/api/category/course", newCourse)
    .then((res) => {
      const { message, course } = res?.data;
      SnackBar.success(message);
      history.push(`/admin/course/${course?._id}`);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Add New Video
export const addNewVideo = (newVideo) => (dispatch) => {
  axios
    .put(`/api/category/courseVideo/${newVideo?.relatedCourse?._id}`, newVideo)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      history.push(`/admin/course/${newVideo?.relatedCourse?._id}`);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit Video
export const editVideo = (video) => (dispatch) => {
  axios
    .put(`/api/category/editVideo/${video?.videoId}`, video)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      dispatch(getParticularCourse(video.courseId));
      // history.push(`/admin/course/${newVideo?.relatedCourse?._id}`);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Assign New Course
export const assignNewCourse = (courseDetails) => (dispatch) => {
  axios
    .post(`/api/category/assignCourse/${courseDetails.userId}`, courseDetails)
    .then((res) => {
      const { message, course } = res?.data;
      SnackBar.success(message);
      dispatch(getAssignedCourses(courseDetails.userId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Assigned Courses
export const getAssignedCourses = (userId) => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get(`/api/category/assignedCourses/${userId}`)
    .then((res) => {
      dispatch({
        type: GET_ASSIGNED_COURSES_LIST,
        payload: {
          assignedCourses: res?.data?.message?.assignedCourses,
          filteredAssignedCourseList: res?.data?.message?.assignedCourses,
        },
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Particular Course
export const getParticularCourse = (courseId) => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get(`/api/category/course/${courseId}`)
    .then((res) => {
      dispatch({
        type: GET_PARTICULAR_COURSES_LIST,
        payload: res?.data?.message,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Particular Course Videos and Documents
export const getVideosAndDocuments = (courseId) => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get(`/api/category/courseMeta/${courseId}`)
    .then((res) => {
      dispatch({
        type: GET_PARTICULAR_COURSES_FULL_LIST,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Course Documents
export const getCourseDocuments = (courseId) => (dispatch) => {
  dispatch(coursesLoading());
  axios
    .get(`/api/category/getDocuments/${courseId}`)
    .then((res) => {
      dispatch({
        type: GET_COURSE_DOCUMENTS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// audit Course Info
export const auditCourseInfo = (videoAuditDetails) => (dispatch) => {
  axios
    .post("/api/category/videoAudit", videoAuditDetails)
    .then((res) => {
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response);
    });
};

// Upload Course Image
export const uploadCourseImage = (file) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post("/api/category/addCourseImage", file)
    .then((res) => {
      dispatch({
        type: UPLOAD_COURSE_IMAGE,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Upload Course Documents
export const uploadCourseDocuments = (file) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post("/api/category/addCourseDocument", file)
    .then((res) => {
      dispatch({
        type: UPLOAD_COURSE_DOCUMENTS,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit Course Document Display order
export const editDocumentDisplayOrder = (data) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .put(`/api/category/editDocumentOrder/${data.documentId}`, data)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Upload File process
export const uploadFileProcess = () => {
  return {
    type: UPLOAD_FILE_PROCESS,
  };
};

// audit Course Document
export const addCourseDocument = (data) => (dispatch) => {
  axios
    .post("/api/category/addDocument", data)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      history.push(`/admin/course/${data.courseId}`);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit Course Details
export const editCourseDetails = (data) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .put(`/api/category/editCourseDetails`, data)
    .then((res) => {
      const { message } = res?.data;
      SnackBar.success(message);
      dispatch(getParticularCourse(data.courseId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Search results
export const getSearchResults = (data) => (dispatch) => {
  dispatch(coursesLoading());
  console.log(data);
  if (data.pathname === "myCourses") {
    dispatch({
      type: GET_ASSIGNED_COURSES_LIST,
      payload: {
        assignedCourses: data.assignedCourses,
        filteredAssignedCourseList: data.filteredCourseList,
      },
    });
  } else {
    dispatch({
      type: GET_ALL_COURSES,
      payload: {
        coursesList: data.coursesList,
        filteredCourseList: data.filteredCourseList,
      },
    });
  }
};

// Get Partiular Video or info
export const getParticularVideoOrInfo = (videoId) => {
  return {
    type: GET_PARTICULAR_COURSE_VIDEO_INFO,
    payload: videoId,
  };
};

// Courses Loading
export const coursesLoading = () => {
  return {
    type: COURSES_LOADING,
  };
};

// Courses Loading
export const clearCourseImage = () => {
  return {
    type: UPLOAD_COURSE_IMAGE,
    payload: null,
  };
};
