import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography } from '@material-ui/core';
import TextFieldGroup from '../../reusableComponents/TextFieldGroup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotPassword } from '../../redux/actions/authActions';
import * as Yup from 'yup';
import ButtonField from '../../reusableComponents/ButtonField';

const ForgotPassword = ({
    forgotPassword
}) => {

    const formik = useFormik({
        initialValues: {
            userNameOrEmail: ''
        },
        validationSchema: Yup.object({
            userNameOrEmail: Yup.string()
                .email('Email is invalid').required('Email is required field')
        }),
        onSubmit: async(values) => {
            await forgotPassword(values);
        }
    });

    return ( 
        <section className="login">
        <Container maxWidth="lg" className="forgotPasswordContainer">
            <Grid container>
                <Grid item xs={12} className="forgotPasswodInnerContainer">
                    <Card className="card">
                        <CardContent className="forgotPasswodCardContent">
                            <Typography className="headingText" variant="h6">Forgot  your Password?</Typography>
                            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off" className="formContainer">
                                <TextFieldGroup 
                                    label="Email"
                                    type="text"
                                    name="userNameOrEmail"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.userNameOrEmail}
                                    errors={formik.touched.userNameOrEmail && formik.errors.userNameOrEmail ? formik.errors.userNameOrEmail : null}
                                /> 
                               
                                <div className="submitButton">  
                                <p className="paragraph"> Check Your E-mail to reset Your Password </p> 
                                    <ButtonField 
                                        buttonStyle="formSubmit"
                                        type="submit"
                                        name="Submit"
                                    />
                                </div>
                            </form>
                            <p className="alreadyAccountContainer">Already have an account? <Typography variant="subtitle2"><Link className="alreadyAccount" to="sign-In">Sign in</Link></Typography></p>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container> 
        </section> 
    );
};

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func.isRequired
}

const mapDispatchToProps = { forgotPassword }

export default connect(null, mapDispatchToProps)(ForgotPassword);