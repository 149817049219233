import { combineReducers } from "redux";

import authReducer from "./authReducer";
import accountsReducer from "./AccountsReducer";
import coursesReducer from "./CoursesReducer";
import programReducer from "./programReducer";
import ibridgeReducer from "./ibridgeReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  courses: coursesReducer,
  programs: programReducer,
  ibridge: ibridgeReducer,
});

export default rootReducer;
