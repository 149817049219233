import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableSortLabel,
  TablePagination,
  Toolbar,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../reusableComponents/ButtonField";
import isEmpty from "../../reusableComponents/is-empty";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(3, 4),
  },
  pageHeaderView: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  titleText: {
    fontWeight: 600,
  },
  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: 900,
      color: "white",
      backgroundColor: "#003399",
    },
    "& tbody td": {
      color: "#2B2B2B",
      fontWeight: 400,
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    backgroundColor: "transparent",
    borderColor: "1px solid #3d4977",
    color: "#3d4977",
    textTransform: "capitalize",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Row = ({ list, columns, onCellClick }) => {
  return (
    <TableRow key={`tr-${list._id}`} onClick={() => onCellClick(list)}>
      {columns.map((colList, i) => {
        return (
          <TableCell key={`trc-${i}`}>
            {colList.render ? colList.render(list) : list[colList.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ({
  titleText,
  columns,
  data,
  searchField,
  addNewText,
  addOnClick,
  onCellClick,
}) => {
  const classes = useStyles();

  const pages = [7, 14, 21];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState([pages[page]]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filterData, setFilterData] = useState({
    fn: (data) => {
      return data;
    },
  });
  const [colSearch, setColSearch] = useState("");

  useEffect(() => {
    setColSearch(columns.map((list) => list.field)[0]);
  }, []);

  const handeSearch = (e) => {
    let target = e.target;
    setFilterData({
      fn: (data) => {
        if (target.value === "") {
          return data;
        } else {
          const regex = new RegExp(`^${target.value}`, "i");
          return data?.filter((x) => regex.test(x[`${colSearch}`]));
        }
      },
    });
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Pagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={filterData.fn(data)?.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterData.fn(data),
      getComparator(order, orderBy)
    )?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return (
    <TableContainer component={Paper} className={classes.pageContent}>
      {!isEmpty(titleText) && (
        <Typography variant="h6" component="div" className={classes.titleText}>
          {titleText}
        </Typography>
      )}
      {searchField && (
        <Toolbar className={classes.pageHeaderView}>
          <TextFieldGroup
            label="Search"
            inputClassName={classes.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={handeSearch}
          />
          {addNewText && (
            <ButtonField
              buttonStyle={classes.newButton}
              variant="outlined"
              name={addNewText}
              icon={<AddIcon />}
              onClick={addOnClick}
            />
          )}
        </Toolbar>
      )}
      <Table className={classes.table}>
        <TableHead>
          {columns.map((list, i) => (
            <TableCell
              key={`thc-${i}`}
              sortDirection={orderBy === list.title ? order : false}
            >
              {list.disableSorting ? (
                list.title
              ) : (
                <TableSortLabel
                  active={orderBy === list.title}
                  direction={orderBy === list.title ? order : "asc"}
                  onClick={() => handleSortRequest(list.title)}
                >
                  {list.title}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {!isEmpty(recordsAfterPagingAndSorting()) ? (
            recordsAfterPagingAndSorting()?.map((list) => (
              <Row list={list} columns={columns} onCellClick={onCellClick} />
            ))
          ) : (
            <TableRow>
              <TableCell colspan="2" style={{ "text-align": "center" }}>
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!isEmpty(recordsAfterPagingAndSorting()) && <Pagination />}
    </TableContainer>
  );
};
