import {
  COURSES_LOADING,
  GET_ALL_COURSES,
  GET_ALL_COURSES_LIST,
  GET_ASSIGNED_COURSES_LIST,
  GET_PARTICULAR_COURSES_LIST,
  GET_PARTICULAR_COURSE_VIDEO_INFO,
  UPLOAD_COURSE_IMAGE,
  UPLOAD_FILE_PROCESS,
  UPLOAD_COURSE_DOCUMENTS,
  GET_COURSE_DOCUMENTS,
  GET_PARTICULAR_COURSES_FULL_LIST,
} from "../actions/types";

const initialState = {
  loading: false,
  coursesList: [],
  allCoursesList: [],
  assignedCourses: [],
  particularCourse: null,
  particularVideoInfo: null,
  courseImage: null,
  courseDocuments: null,
  uploadSurveyFileProcess: false,
  courseVideosAndDocuments: null,
  documents: [],
  filteredCourseList: [], 
  filteredAssignedCourseList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COURSES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_COURSES:
      return {
        ...state,
        coursesList: action.payload.coursesList,
        filteredCourseList: action.payload.filteredCourseList,
        loading: false,
      };
    case UPLOAD_COURSE_IMAGE:
      return {
        ...state,
        courseImage: action.payload,
        uploadSurveyFileProcess: false,
        loading: false,
      };
    case UPLOAD_COURSE_DOCUMENTS:
      return {
        ...state,
        courseDocuments: action.payload,
        uploadSurveyFileProcess: false,
        loading: false,
      };
    case UPLOAD_FILE_PROCESS:
      return {
        ...state,
        uploadSurveyFileProcess: true,
      };
    case GET_ALL_COURSES_LIST:
      return {
        ...state,
        allCoursesList: action.payload,
        loading: false,
      };
    case GET_ASSIGNED_COURSES_LIST:
      return {
        ...state,
        assignedCourses: action.payload.assignedCourses || [],
        filteredAssignedCourseList: action.payload.filteredAssignedCourseList || [],
        loading: false,
      };

    case GET_COURSE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        loading: false,
      };
    case GET_PARTICULAR_COURSES_LIST:
      return {
        ...state,
        particularCourse: action.payload,
        loading: false,
      };

    case GET_PARTICULAR_COURSES_FULL_LIST:
      return {
        ...state,
        courseVideosAndDocuments: action.payload,
        loading: false,
      };
    case GET_PARTICULAR_COURSE_VIDEO_INFO:
      const { particularCourse } = state;
      const videoId = action.payload;
      const videoInfo = particularCourse?.videos.find(
        (list) => list._id === videoId
      );
      return {
        ...state,
        particularVideoInfo: videoInfo,
        loading: false,
      };
    default:
      return state;
  }
}
