import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import {
  getAllCoursesList,
  addNewVideo,
  uploadCourseDocuments,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  prerequisite: {
    margin: theme.spacing(2, 0),
  },
  btnContailer: {
    margin: theme.spacing(2, 0),
  },
  btnSubmit: {
    borderRadius: "16px",
    float: "right",
    backgroundColor: "#003399",
    color: "white",
    //opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      //opacity: "1",
    },
  },
}));

const NewProgramForm = ({
  auth,
  courses,
  getAllCoursesList,
  //addNewVideo,
  //uploadCourseDocuments,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: "",
      path: "",
      learningPath: "",
      duration: "",
      description: "",
      selectedCourse: {},
      price: "",
    },
    validationSchema: Yup.object({
      programName: Yup.string()
        .trim()
        .required("Program Name is required field"),
      programPath: Yup.string()
        .trim()
        .required("Program Path is required field"),
      programLearningPath: Yup.string()
        .trim()
        .required("Program Learning Path is required field"),
      programDuration: Yup.string()
        .trim()
        .required("Program Duration is required field"),
      programDescription: Yup.string()
        .trim()
        .required("Program Description is required field"),
      price: Yup.string().trim().required("Pris required field"),

      // fileUpload: Yup.string()
      //     .required('file upload is required field'),

      relatedCourse: Yup.object().required(
        "Video Related Course is required field"
      ),
    }),
    onSubmit: async (values) => {
      let videoData = JSON.parse(JSON.stringify(values));
      videoData.prerequisiteVideos = values.prerequisiteVideos.map(
        (video) => video._id
      );
      videoData.orderId = parseInt(values.orderId);
      // videoData.courseDocuments = {
      //   key: courseDocuments.key,
      //   link: courseDocuments.link,
      //   name: courseDocuments.name,
      // };

      // console.log(videoData);

      await addNewVideo(videoData);
    },
  });

  const { isAuthenticated } = auth;
  const { allCoursesList, uploadSurveyFileProcess, courseDocuments } = courses;
  const [videoOrderList, setVideoOrderList] = useState([]);

  useEffect(() => {
    if (isEmpty(allCoursesList) && isAuthenticated) {
      getAllCoursesList();
    }
  }, [allCoursesList, isAuthenticated]);

  useEffect(() => {
    console.log(formik.values.relatedCourse);
    if (formik.values?.relatedCourse?.videos) {
      setVideoOrderList(formik.values.relatedCourse?.videos);
    } else {
      setVideoOrderList([]);
    }
  }, [formik.values.relatedCourse]);

  const uploadDocuments = (e) => {
    formik.setFieldValue("courseDocument", e.target.files[0]);
    formik.setFieldValue("courseDocumentName", e.target.files[0].name);
    // e.preventDefault();

    const formData = new FormData();
    formData.append("courseDocument", e.target.files[0]);
    uploadCourseDocuments(formData);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Select Course"
              name="selectedCourse"
              favourite={allCoursesList}
              onSelect={(course) =>
                formik.setFieldValue("selectedCourse", course)
              }
              // selected={formik.values.relatedCourse}
              errors={
                formik.touched.selectedCourse && formik.errors.selectedCourse
                  ? formik.errors.selectedCourse
                  : null
              }
            />
          </div>
          <TextFieldGroup
            label=" Program Name"
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            errors={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <TextFieldGroup
            label=" Program Path"
            type="text"
            name="description"
            multiline
            rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
          <TextFieldGroup
            label="Program Learning Path"
            type="text"
            name="learningPath"
            multiline
            // rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.learningPath}
            errors={
              formik.touched.learningPath && formik.errors.learningPath
                ? formik.errors.learningPath
                : null
            }
          />
          <TextFieldGroup
            label=" Program Description"
            type="text"
            name="description"
            multiline
            rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <TextFieldGroup
              label="Program Duration"
              type="text"
              name="duration"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.duration}
              errors={
                formik.touched.duration && formik.errors.duration
                  ? formik.errors.duration
                  : null
              }
            />
            <TextField
              id="datetime-local"
              label="Start Date"
              type="datetime-local"
              //defaultValue="2017-05-24T10:30"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label="End Date"
              type="datetime-local"
              //defaultValue="2017-05-24T10:30"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextFieldGroup
              label="Price"
              type="text"
              name="price"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
              errors={
                formik.touched.price && formik.errors.price
                  ? formik.errors.price
                  : null
              }
            />
          </div>

          <div className={classes.btnContailer}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Submit "
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

NewProgramForm.propTypes = {
  getAllCoursesList: PropTypes.func.isRequired,
  addNewProgram: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  //uploadCourseDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  getAllCoursesList,
  // addNewProgram,
  //uploadCourseDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProgramForm);
