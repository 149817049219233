import React from 'react';
import PageHeader from '../../../reusableComponents/PageHeader';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles} from '@material-ui/core';
import NewVideoForm from './NewVideoForm';
import { Container,Paper } from "@material-ui/core";  


const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(0, 0),
        padding: theme.spacing(0)
    },
    page:{
     
        padding: theme.spacing(2)
    }
}))

const NewVideo = () => {
    const classes = useStyles();
    return (
        <> 
         <Container maxWidth="lg" > 
         <Paper className={classes.pageContent}>
            <PageHeader 
                title="Add New Video"
                subTitle="Add new videos to the course"
                icon={<PlayCircleOutlineIcon fontSize="large" />}
            /> 
            </Paper>
            <Paper className={classes.page}>
                <NewVideoForm />
            </Paper> 
            </Container>
        </>
    );
};

export default NewVideo;