// Auth User
export const SET_CURRENT_LOADING = "SET_CURRENT_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Accounts
export const GET_ACCOUNT_USERS_LOADING = "GET_ACCOUNT_USERS_LOADING";
export const GET_ACCOUNT_USERS_LIST = "GET_ACCOUNT_USERS_LIST";
export const GET_ACCOUNT_USER_PROFILE = "GET_ACCOUNT_USER_PROFILE";
export const GET_ACCOUNT_ADMINS_LIST = "GET_ACCOUNT_ADMINS_LIST"; 
//export const GET_ACCOUNT_COACH_LIST = "GET_ACCOUNT_COACH_LIST";
export const GET_ACCESS_LOG_LIST = "GET_ACCESS_LOG_LIST";

// Courses
export const COURSES_LOADING = "COURSES_LOADING";
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_ALL_COURSES_LIST = "GET_ALL_COURSES_LIST";
export const GET_ASSIGNED_COURSES_LIST = "GET_ASSIGNED_COURSES_LIST";
export const GET_PARTICULAR_COURSES_LIST = "GET_PARTICULAR_COURSES_LIST";
export const GET_PARTICULAR_COURSES_FULL_LIST =
  "GET_PARTICULAR_COURSES_FULL_LIST";
export const GET_PARTICULAR_COURSE_VIDEO_INFO =
  "GET_PARTICULAR_COURSE_VIDEO_INFO";
export const UPLOAD_COURSE_IMAGE = "UPLOAD_COURSE_IMAGE";
export const UPLOAD_COURSE_DOCUMENTS = "UPLOAD_COURSE_DOCUMENTS";
export const UPLOAD_FILE_PROCESS = "UPLOAD_FILE_PROCESS";
export const GET_COURSE_DOCUMENTS = "GET_COURSE_DOCUMENTS";

//Videos
export const GET_USER_COURSE_VIDEO_PROGRESS = "GET_USER_COURSE_VIDEO_PROGRESS";

//Programs
export const PROGRAMS_LOADING = "PROGRAMS_LOADING";
export const ADD_NEW_PROGRAM = "ADD_NEW_PROGRAM";
export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";

//Ibridge
export const IBRIDGE_COURSE_CONTENT = "IBRIDGE_COURSE_CONTENT";
