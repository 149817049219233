import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import { editVideo } from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  form: {
    //display: 'flex',
    //flexDirection: 'column',
    //margin: 'auto',
    width: "fit-content",
    //backgroundColor: '#0009',
    marginTop: theme.spacing(1),
    maxWidth: "500px",
  },

  buttonContainer: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "flex-start",
  },
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  prerequisite: {
    margin: theme.spacing(2, 0),
  },
  formSubmit: {
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    background: "#3d4977",
    color: "#F8F9F9",
    "&:hover": {
      background: "#1e293a",
    },
  },
}));

const VideoEdit = ({
  editVideo,
  auth,
  videoData,
  courseId,
  setEditPopup,
  videoOrders,
  type,
}) => {
  const [videoOrderList, setVideoOrderList] = useState([]);
  const previewList = [
    { title: "Yes", value: true },
    { title: "No", value: false },
  ];
  const visibility = [
    { title: "Hide", value: false },
    { title: "Unhide", value: true },
  ];

  const [duration, setDuration] = useState();

  const formik = useFormik({
    initialValues: {
      orderId: "",
      title: "",
      description: "",
      embedUrl: "",
      prerequisiteVideos: [],
      preview: "No",
      selectedOrder: {},
      visibility: {},
    },
    validationSchema: Yup.object({
      orderId: Yup.string().trim(),
      title: Yup.string().trim().required("Video Title is required field"),
      embedUrl: Yup.string().trim().required("Embeded code is required field"),
      description: Yup.string()
        .trim()
        .required("Description is required field"),
    }),

    onSubmit: async (values) => {
      let data = JSON.parse(JSON.stringify(values));
      data.orderId = parseInt(values.orderId) || parseInt(videoData?.orderId);
      const { user } = auth;
      data.courseId = courseId;
      data.videoId = videoData._id;
      data.duration = duration;
      data.visibility = values?.visibility?.value;
      // || videoData?.visibility;
      if (isEmpty(data.orderId)) {
        delete data.orderId;
      }
      setEditPopup(false);
      console.log("data", data);
      await editVideo(data);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "orderId",
      videoData && videoData?.orderId && videoData?.orderId?.toString()
        ? videoData.orderId.toString()
        : ""
    );
    formik.setFieldValue(
      "title",
      videoData && videoData.title ? videoData.title : ""
    );
    formik.setFieldValue(
      "description",
      videoData && videoData.description ? videoData.description : ""
    );
    formik.setFieldValue(
      "embedUrl",
      videoData && videoData.embedUrl ? videoData.embedUrl : ""
    );
    formik.setFieldValue(
      "prerequisiteVideos",
      videoData && videoData.prerequisiteVideos
        ? videoData.prerequisiteVideos
        : []
    );
    formik.setFieldValue(
      "preview",
      videoData && videoData.preview ? videoData.preview : false
    );
    formik.setFieldValue(
      "visibility",
      videoData && videoData.visibility === true
        ? { title: "Unhide", value: true }
        : { title: "Hide", value: false }
    );
    const selectedOrder = videoOrders.find(
      (item) => item.orderId === videoData.orderId
    );
    formik.setFieldValue(
      "selectedOrder",
      selectedOrder ? { ...selectedOrder } : {}
    );
  }, [videoData]);

  useEffect(() => {
    const filteredVideos = videoOrders.filter(
      (item) => item._id !== videoData._id
    );
    setVideoOrderList(filteredVideos);
  }, [videoOrders]);

  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <TextFieldGroup
            label="Video Title"
            type="text"
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            errors={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : null
            }
          />
          <TextFieldGroup
            label="Embeded code"
            type="text"
            name="embedUrl"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.embedUrl}
            errors={
              formik.touched.embedUrl && formik.errors.embedUrl
                ? formik.errors.embedUrl
                : null
            }
          />
          <Grid item xs={12} md={12} align="center" style={{ display: "none" }}>
            <ReactPlayer
              className="react-player"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    modestbranding: 1,
                    autoplay: 1,
                    autohide: 0,
                    theme: "dark",
                    cc_load_policy: 1,
                    rel: 0,
                    iv_load_policy: 3,
                    mute: 0,
                    loop: 1,
                  },
                },
              }}
              onDuration={(duration) => setDuration(duration)}
              url={formik?.values?.embedUrl || null}
              width="80%"
              height="100%"
              controls
            />
          </Grid>
          <TextFieldGroup
            label="Video Description"
            type="text"
            name="description"
            multiline
            //rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />

          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Free Preview Video"
              label="Free Preview Video"
              name="preview"
              favourite={previewList}
              onSelect={(list) => formik.setFieldValue("preview", list.value)}
              errors={
                formik.touched.preview && formik.errors.preview
                  ? formik.errors.preview
                  : null
              }
              selected={
                formik.values.preview && formik.values.preview == true
                  ? { title: "Yes", val: true }
                  : { title: "No", val: false }
              }
            />
          </div>

          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Select after which video should this appear"
              label="Select after which video should this appear"
              name="orderId"
              favourite={videoOrderList}
              onSelect={(video) => {
                formik.setFieldValue("orderId", video.orderId);
                console.log("formik.values video", video);
              }}
              selected={
                !isEmpty(formik.values.selectedOrder)
                  ? formik.values.selectedOrder && formik.values.selectedOrder
                  : {}
              }
              errors={
                formik.touched.orderId && formik.errors.orderId
                  ? formik.errors.orderId
                  : null
              }
            />
          </div>

          <div className={classes.prerequisite}>
            <SelectMultipleValues
              showLable={true}
              heading="Select prerequisite videos"
              placeholder="Select prerequisite videos"
              name="prerequisiteVideos"
              values={videoOrderList}
              onSelectValues={(video) => {
                formik.setFieldValue("prerequisiteVideos", video);
              }}
              selectedValues={
                !isEmpty(formik.values.prerequisiteVideos)
                  ? formik.values.prerequisiteVideos &&
                    formik.values.prerequisiteVideos
                  : []
              }
              errors={
                formik.touched.prerequisiteVideos &&
                formik.errors.prerequisiteVideos
                  ? formik.errors.prerequisiteVideos
                  : null
              }
            />
          </div>

          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Hide Video"
              label="Hide Video"
              name="visibility"
              favourite={visibility}
              onSelect={(video) => {
                formik.setFieldValue("visibility", video);
                console.log("formik.values video", video);
              }}
              selected={
                !isEmpty(formik.values.visibility)
                  ? formik.values.visibility && formik.values.visibility
                  : {}
              }
              errors={
                formik.touched.visibility && formik.errors.visibility
                  ? formik.errors.visibility
                  : null
              }
            />
          </div>

          <div className={classes.buttonContainer}>
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="save"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

VideoEdit.propTypes = {
  editVideo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { editVideo };

export default connect(mapStateToProps, mapDispatchToProps)(VideoEdit);
