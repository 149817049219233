import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Paper, Typography } from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import { getUserProfile } from "../../../redux/actions/AccountsActions";
import { getAssignedCourses } from "../../../redux/actions/CoursesActions";
import AssignCourse from "./AssignCourse";
import Table from "../../MaterialTable/Table";
import AssignProgram from "./AssignProgram";

//import {Link} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  gridAssignRoot: {
    margin: theme.spacing(4, 0),
  },
  paperRoot: {
    backgroundColor: "#fdfdff",
    padding: theme.spacing(3, 4),
  },
  pageAssignHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  pageAssignIcon: {
    display: "inline-block",
    padding: theme.spacing(1),
    color: "#3d4977",
  },
  pageAssignTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
}));

const User = ({
  match,
  getUserProfile,
  auth,
  accounts,
  courses,
  getAssignedCourses,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { accountUserProfile } = accounts;
  const { assignedCourses } = courses;
  const { userId } = match.params;

  useEffect(() => {
    getUserProfile(userId);
    getAssignedCourses(userId);
  }, []);

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Image",
      field: "image",
      disableSorting: true,
      cellStyle: {
        width: 100,
        maxWidth: 100,
      },
      headerStyle: {
        width: 100,
        maxWidth: 100,
      },
      render: (rowData) => (
        <img style={{ height: 50 }} src={rowData.image} alt="" />
      ),
    },
    {
      title: "Course Progress",
      field: "courseProgress",
    },

    {
      title: "Course Duration",
      field: "duration",
    },
    {
      title: "Course Completed Percentage",
      field: "percentage",
    },
  ];

  const data = assignedCourses?.map((list) => {
    const { _id, title, courseImage, percentage, duration, courseProgress } =
      list;
    const length = new Date(1000 * (duration ? duration : 0))
      .toISOString()
      .substr(11, 8)
      .replace("00:", "");
    const userCourseProgress = new Date(
      1000 * (courseProgress ? courseProgress : 0)
    )
      .toISOString()
      .substr(11, 8)
      .replace("00:", "");
    console.log(userCourseProgress);
    return {
      _id: _id,
      name: title,
      image: courseImage?.link,
      percentage: `${percentage} %`,
      duration: length || "Course Duration not available",
      courseProgress: userCourseProgress,
      percentage: `${percentage}%`,
    };
  });

  const userData = () => {
    return (
      accountUserProfile &&
      Object?.keys(accountUserProfile).map((list, i) => (
        <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              {list}
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={accountUserProfile[list]}
              />
            </Grid>
          </Grid>
        </Grid>
      ))
    );
  };

  return (
    <>
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                User Details
              </Typography>
            </div>
          </div>
          <Grid container spacing={3}>
            {userData()}
          </Grid>
        </Paper>
      </div>
      <Table
        titleText="Assigned Courses List"
        columns={columns}
        data={data}
        searchField
        onCellClick={(course) => history.push(`/admin/course/${course._id}`)}
      />
      <div className={classes.gridAssignRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <AddBoxIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Assign New Course
              </Typography>
            </div>
          </div>
          <AssignCourse userId={userId} />
        </Paper>
      </div>
      {/* <div className={classes.gridAssignRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <AddBoxIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Assign New Program
              </Typography>
            </div>
          </div>
          <AssignProgram userId={userId} />
        </Paper>
      </div> */}
    </>
  );
};

User.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  getAssignedCourses: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
  courses: state.courses,
});

const mapDispatchToProps = { getUserProfile, getAssignedCourses };

export default connect(mapStateToProps, mapDispatchToProps)(User);
