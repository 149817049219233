import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllCourses,
  getSearchResults,
  getAssignedCourses,
} from "../../redux/actions/CoursesActions";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types"; 
// import {
//   createStyles,
//   fade,
//   Theme,
//   makeStyles,
// } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   search: {
//     position: "relative", 
//     padding : "0rem, 2rem", 
//     borderRadius: theme.shape.borderRadius,
//     // backgroundColor: fade(theme.palette.common.white, 0.15),
//     // "&:hover": {
//     //   backgroundColor: fade(theme.palette.common.white, 0.25),
//     // },
//     margin: theme.spacing(3),
//     width: "60%",
//     [theme.breakpoints.up("sm")]: {
//       marginLeft: "40%",
//       width: "20%",
//     },
//   },  

//   searchIcon: {
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     //alignItems: "center",
//     //justifyContent: "center",
//   },



// }));

function SearchBar({
  getAssignedCourses,
  getAllCourses,
  getSearchResults,
  courses,
  userId,
  auth,
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const { isAuthenticated } = auth;
  const { assignedCourses, coursesList } = courses;
  const location = useLocation();

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter =
      location.pathname === "/courses"
        ? assignedCourses?.filter((value) => {
            return value.title
              .toLowerCase()
              .includes(searchWord?.toLowerCase());
          })
        : coursesList?.filter((value) => {
            return value.title
              .toLowerCase()
              .includes(searchWord?.toLowerCase());
          });
    if (searchWord === "") {
      setFilteredData([]);
      if (location.pathname === "/courses") {
        const data = {
          assignedCourses: assignedCourses,
          filteredCourseList: assignedCourses,
          pathname: "myCourses",
        };
        getSearchResults(data);
      } else {
        const data = {
          coursesList: coursesList,
          filteredCourseList: coursesList,
          pathname: "allCourses",
        };
        getSearchResults(data);
      }
    } else {
      setFilteredData(newFilter);
      console.log(filteredData);
      if (location.pathname === "/courses") {
        const data = {
          assignedCourses: assignedCourses || [],
          filteredCourseList: newFilter || [],
          pathname: "myCourses",
        };
        getSearchResults(data);
      } else {
        const data = {
          coursesList: coursesList,
          filteredCourseList: newFilter,
          pathname: "allCourses",
        };
        getSearchResults(data);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log(location.pathname);
      if (location.pathname === "/courses") {
        getAssignedCourses(userId);
      } else {
        getAllCourses();
      }
    }
  }, [isAuthenticated]); 

  useEffect(() => {
    clearInput()
    document.getElementById("searchTextfield").value=""
  }, [location.pathname])

  const clearInput = () => {
    setFilteredData([]);
    if (location.pathname === "/courses") {
      const data = {
        assignedCourses: assignedCourses,
        filteredCourseList: assignedCourses,
        pathname: "myCourses",
      };
      getSearchResults(data);
    } else {
      const data = {
        coursesList: coursesList,
        filteredCourseList: coursesList,
        pathname: "allCourses",
      };
      getSearchResults(data);
    }
    setWordEntered("");
  };
  

  return (
    <div className="search">
      <div className="searchInputs">
        {console.log(filteredData)}
        <input 
          type="text" 
          id="searchTextfield"
          placeholder={"Search Courses ..."}
          value={wordEntered}
          onChange={handleFilter}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  getAssignedCourses: PropTypes.func.isRequired,
  getAllCourses: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  getAssignedCourses,
  getAllCourses,
  getSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

//export default SearchBar;
