import {
  GET_ACCOUNT_USERS_LOADING,
  GET_USER_COURSE_VIDEO_PROGRESS,
  GET_ACCOUNT_USERS_LIST,
  GET_ACCOUNT_USER_PROFILE,
  CLEAR_ACCOUNT_USER_PROFILE,
  GET_ACCOUNT_ADMINS_LIST,
  GET_ACCESS_LOG_LIST,
} from "../actions/types";

const initialState = {
  loading: false,
  accountUsersList: [],
  accountUserProfile: null,
  accountAdminsList: [],
  accessLogList: [],
  userCourseVideoProgress: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_USERS_LIST:
      return {
        ...state,
        accountUsersList: action.payload,
        loading: false,
      };
    case GET_ACCOUNT_USER_PROFILE:
      return {
        ...state,
        accountUserProfile: action.payload,
        loading: false,
      };
    case GET_ACCOUNT_ADMINS_LIST:
      return {
        ...state,
        accountAdminsList: action.payload,
        loading: false,
      };
    case GET_USER_COURSE_VIDEO_PROGRESS:
      return {
        ...state,
        userCourseVideoProgress: action.payload,
        loading: false,
      };
    case GET_ACCESS_LOG_LIST:
      return {
        ...state,
        accessLogList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
