import { GET_PROGRAM_LIST, PROGRAMS_LOADING } from "../actions/types";

const initialState = {
  loading: false,
  programList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRAMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAM_LIST:
      return {
        ...state,
        programList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
