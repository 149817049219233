import React, { useState } from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
// const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MUIDataTableHeadCell: {
//         fixedHeader: {
//           backgroundColor: "#3c44b126",
//         },
//       },
//       MuiTableCell: {
//         /* color: rgba(0, 0, 0, 0.87); */
//         head: {
//           color: "black",
//           fontWeight: "bold",
//         },
//       },
//       MUIDataTableSelectCell: {
//         root: {
//           display: "none",
//         },
//         headerCell: {
//           backgroundColor: "#2196F3",
//         },
//       },
//       MUIDataTable: {
//         root: {},
//         paper: {
//           boxShadow: "none",
//         },
//         responsiveScrollMaxHeight: {
//           // margin : "0px 50px",
//           border: "1px solid #dee2e6",
//           borderCollapse: "collapse",
//         },
//       },
//       MuiTypography: {
//         h6: {
//           marginLeft: "30px",
//           fontWeight: "bold",
//           color: "#2196F3",
//         },
//       },
//       MUIDataTableBodyRow: {
//         root: {
//           "&:nth-child(odd)": {
//             backgroundColor: "#dee2e6",
//           },
//         },
//       },
//       MUIDataTableBodyCell: {},
//     },
//   });

const useStyles = makeStyles((theme) => ({
  newButton: {
    backgroundColor: "#003399",
    borderColor: "1px solid #3d4977",
    color: "white",
    textTransform: "capitalize",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#003399",
    },
  },
}));

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const Tables = ({
  data,
  columns,
  options,
  addNewText,
  addOnClick,
  selectedRow,
}) => {
  const classes = useStyles();
  return (
    // <MuiThemeProvider theme={getMuiTheme()}>
    <MaterialTable
      icons={tableIcons}
      title={
        addNewText ? (
          <Button
            className={classes.newButton}
            variant="outlined"
            onClick={addOnClick}
          >
            <AddIcon /> {addNewText}
          </Button>
        ) : null
      }
      data={data}
      columns={columns}
      options={options}
      onRowClick={(evt, rowData) => selectedRow(rowData)}
    />
    // </MuiThemeProvider>
  );
};

export default Tables;
