import React from 'react';
import PageHeader from '../../../reusableComponents/PageHeader';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles, Paper } from '@material-ui/core';
import NewProgramForm from './NewProgramForm';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(4, 0),
        padding: theme.spacing(4)
    }
}))

const NewProgram = () => {
    const classes = useStyles();
    return (
        <>
            <PageHeader 
                title="Add New Program"
                subTitle="Enter new Program details"
                icon={<AddBoxIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
                <NewProgramForm/>
            </Paper>
        </>
    );
};

export default NewProgram;