import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography } from '@material-ui/core';
import TextFieldGroup from '../../reusableComponents/TextFieldGroup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetPassword } from '../../redux/actions/authActions';
import * as Yup from 'yup';
import ButtonField from '../../reusableComponents/ButtonField';

const ResetPassword = ({
    resetPassword
}) => {

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { token } = useParams();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Password field is required field').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/i, 'min 6 char with 1 lower, upper, number & symbol'),
            confirmPassword: Yup.string()
                .required('Confirm Password field is required field').oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: async(values) => {
            const userPassword = {
                newPassword: values.password,
                confirmPassword: values.confirmPassword,
                token
            }
            await resetPassword(userPassword);
        }
    });

    return ( 
        <section className="login">
        <Container maxWidth="lg" className="resetPasswordContainer">
            <Grid container className="login">
                <Grid item xs={12} className="resetPasswodInnerContainer">
                    <Card>
                        <CardContent className="resetPasswodCardContent">
                            <Typography className="headingText" variant="h6">Reset Password</Typography>
                            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                                <TextFieldGroup 
                                    label="New Password"
                                    type={showNewPassword ? "text" : "password"}
                                    name="password"
                                    passwordControl={() => setShowNewPassword(!showNewPassword)}
                                    showPassword={showNewPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    errors={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                                />
                                <TextFieldGroup 
                                    label="Confirm Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    passwordControl={() => setShowConfirmPassword(!showConfirmPassword)}
                                    showPassword={showConfirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    errors={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : null}
                                />
                                <div className="submitButton">
                                    <ButtonField 
                                        buttonStyle="formSubmit"
                                        type="submit"
                                        name="Submit"
                                    />
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container> 
        </section>
    );
};

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired
}

const mapDispatchToProps = { resetPassword }

export default connect(null, mapDispatchToProps)(ResetPassword);