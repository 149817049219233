import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import { getAllCourses } from "../../../redux/actions/CoursesActions";
import {
  searchAccessLog,
  getAccessLog,
} from "../../../redux/actions/AccountsActions";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import ButtonField from "../../../reusableComponents/ButtonField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(1, 0),
  },
  formSubmit: {
    textTransform: "capitalize",
    backgroundColor: "#003299",
    marginRight: "20px",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#F8F9F9",
    "&:hover": {
      background: "#004499",
    },
  },
  multiselect: {
    marginTop: "10px",
  },
}));

const AccessLogFilters = ({
  getAllCourses,
  courses,
  auth,
  searchAccessLog,
  getAccessLog,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [courseIds, setCourses] = useState([]);
  const { coursesList } = courses;

  useEffect(() => {
    if (!coursesList || coursesList?.length === 0) {
      getAllCourses();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const courses = courseIds?.map((item) => item?._id);
    const data = {
      startDate,
      endDate,
      courses,
    };
    console.log(data);
    searchAccessLog(data);
  };

  const handleClearFilter = (e) => {
    setStartDate(new Date());
    setEndDate(new Date());
    setCourses([]);
    e.preventDefault();
    getAccessLog();
  };

  const classes = useStyles();
  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={3} className={classes.multiselect}>
          <SelectMultipleValues
            placeholder="Select Courses"
            label="Select Courses"
            name="selectCourse"
            values={coursesList}
            selectedValues={courseIds}
            onSelectValues={(course) => setCourses(course)}
          />
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // margin="normal"
              name="programStartDate"
              label="Select Start Date"
              format="MM/dd/yyyy"
              inputVariant="standard"
              value={startDate}
              className={classes.inputFields}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // margin="normal"
              name="programStartDate"
              label="Select End Date"
              format="MM/dd/yyyy"
              inputVariant="standard"
              value={endDate}
              className={classes.inputFields}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.buttonContainer}>
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="Filter"
            />
            <ButtonField
              buttonStyle={classes.formSubmit}
              onClick={(e) => handleClearFilter(e)}
              type="button"
              name="Clear Filter"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

AccessLogFilters.propTypes = {
  getAllCourses: PropTypes.func.isRequired,
  searchAccessLog: PropTypes.func.isRequired,
  getAccessLog: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});
const mapDispatchToProps = { getAllCourses, searchAccessLog, getAccessLog };

export default connect(mapStateToProps, mapDispatchToProps)(AccessLogFilters);
