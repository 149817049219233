import React from "react";
import FileViewer from "react-file-viewer";
//import xiangmu from "./sar.csv";

//import "./styles.css";
// const file =
//   "https://ibridge.s3.us-east-2.amazonaws.com/IlearnOnline/CourseDocs/1627973299479%2Breact.pdf";
// const type = "pdf";

const onError = (e) => {
  console.log(e, "error in file-viewer");
};

export default function FileView({ file }) {
  let files = file?.split(".");
  let type = files ? files[files?.length - 1] : "";
  return (
    <>
      {/* <div style={{ height: "400px", width: "100%" }}> */}
      <h1>Documents</h1>
      {file && type && (
        <FileViewer
          key={file}
          fileType={type}
          filePath={file}
          onError={onError}
        />
      )}
      {/* </div> */}
    </>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<FileView/>, rootElement);
