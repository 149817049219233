import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CourseCard from "../../reusableComponents/CourseCard";
import { getAssignedCourses } from "../../redux/actions/CoursesActions";
import { postAccessLog } from "../../redux/actions/authActions";
import isEmpty from "../../reusableComponents/is-empty";
//import LastViewCourse from "./LastViewCourse";

const useStyles = makeStyles((theme) => ({
  courseContaier: {
    margin: theme.spacing(3, 0),
  },
}));

const Courses = ({
  getAssignedCourses,
  postAccessLog,
  auth,
  courses,
  history,
}) => {
  const classes = useStyles();

  const { isAuthenticated, user } = auth;
  const { filteredAssignedCourseList, assignedCourses } = courses;
  const [timeInterval, setTimeInterval] = useState(0);
  let tabVisibility = document.visibilityState;
  //const {userId, courseId, videoId} = match.params;

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "My Courses",
        recordedDate: new Date(),
      };
      postAccessLog(data);
    }
  }, [timeInterval]);

  useEffect(() => {
    if (isEmpty(filteredAssignedCourseList) && isAuthenticated) {
      getAssignedCourses(user._id);
    }
  }, [assignedCourses, isAuthenticated]);
       

  
  const returnAssignedCourses = () => { 
    
    return filteredAssignedCourseList?.map((list) => ( 
      <Grid key={list._id} item xs={12} md={6} lg={3} spacing={2}>  
     <CourseCard
          data={list}
          onClick={() => history.push(`/course/${list._id}`)}
        />
      </Grid>
    ));
  };

  return (  
   
    <Container maxWidth="xl" className={classes.courseContaier}> 
        {/* <LastViewCourse  /> */}
      <Grid container spacing={5}>
        {filteredAssignedCourseList?.length > 0 ? (
          returnAssignedCourses()
        ) : (
          <Typography
            align="center"
            style={{
              marginTop: "130px", 
              marginLeft: "550px",
              fontWeight: "bolder",
              fontSize: "24px",
              color: "#003399",
            }}
          >
            No Courses Assigned
          </Typography>
        )}
      </Grid>
    </Container>
  );
};

Courses.propTypes = {
  getAssignedCourses: PropTypes.func.isRequired,
  postAccessLog: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = { getAssignedCourses, postAccessLog };

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
