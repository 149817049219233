import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import {
  Container,
  Grid,
  AppBar,
  makeStyles,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  getParticularCourse,
  getParticularVideoOrInfo,
  getVideosAndDocuments,
} from "../../redux/actions/CoursesActions";
import isEmpty from "../../reusableComponents/is-empty";
import ButtonField from "../../reusableComponents/ButtonField";
import { LabelOffRounded } from "@material-ui/icons";
import { RiPushpin2Fill, RiVideoAddLine, RiFile3Fill } from "react-icons/ri";
import clsx from "clsx";
import FileView from "./FileViews";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border: "10px solid #003399",
  },
  cardContent: {
    padding: theme.spacing(2, 1),
  },
  title: {
    marginLeft: theme.spacing(55),
    paddingTop: theme.spacing(2),
    color: "#003399",
    fontSize: 16,
    fontWeight: 650,
  },
  link: {
    fontWeight: 600,
    color: "#003399",
    display: "flex",
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  linkStyle: {
    background: "#003399",
    fontWeight: 600,
  },
  courseDisplay: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  },
  courseTitleGreen: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  courseTitleRed: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "red",
    fontWeight: 600,
    "&:hover": {
      color: "red",
      cursor: "default",
    },
  },
  courseTitleActive: {
    color: "#003399",
  },

  card: {
    border: "3px solid rgba(0, 0, 0, .125)",
    padding: "10px",
    backgroundColor: "#003399",
    color: "white",
  },
  scrollable: {
    backgroundColor: "#f1f1f1",
    height: "470px",
    width: "400px",
    overflowY: "scroll",
    //margin:'15px',
    marginLeft: "7px",
    //textAlign: 'justify',
    padding: "15px",
  },
}));

const FreeVideoView = ({
  getParticularCourse,
  getParticularVideoOrInfo,
  courses,
  match,
  history,
  auth,
  getVideosAndDocuments,
  accounts,
}) => {
  const { user } = auth;
  const { userCourseVideoProgress } = accounts;
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const { particularCourse, particularVideoInfo, courseVideosAndDocuments } =
    courses;
  const { courseId, videoId } = match.params;
  const [value, setValue] = React.useState("1");
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);

  const regex = /www.youtube-nocookie.com/g;

  useEffect(() => {
    getParticularCourse(courseId);

    getVideosAndDocuments(courseId);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isEmpty(particularCourse)) {
      getParticularVideoOrInfo(videoId);
    }
  }, [particularCourse, videoId]);

  //   useEffect(() => {
  //     if (particularVideoInfo?.embedUrl.match(regex) && progress % 2 == 0) {
  //       const videoAuditDetails = {
  //         userId: user._id,
  //         courseId: courseId,
  //         videoId: videoId,
  //         videoDuration: duration.toString(),
  //         videoProgress: progress.toString(),
  //       };
  //       auditCourseInfo(videoAuditDetails);
  //     }
  //     const data = {
  //       userId: user?._id,
  //       courseId: courseId,
  //     };
  //     getVideoProgress(data);
  //   }, [duration, progress]);

  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  const indexValue = (id) => {
    const videoIndex = particularCourse?.videos?.findIndex(
      (list) => list._id === id
    );
    return videoIndex;
  };

  const previous = async () => {
    const getPreviousIndex = (await indexValue(particularVideoInfo?._id)) - 1;
    const previousData = await particularCourse?.videos[getPreviousIndex];
    getParticularVideoOrInfo(previousData._id);
    return (window.location.href = `/preview/${courseId}/${previousData._id}`);
    // history.push(`/course/${courseId}/${previousData._id}`);
  };

  const next = async () => {
    const getNextIndex = (await indexValue(particularVideoInfo?._id)) + 1;
    const nextData = await particularCourse?.videos[getNextIndex];
    getParticularVideoOrInfo(nextData._id);
    return (window.location.href = `/preview/${courseId}/${nextData._id}`);
    // history.push(`/course/${courseId}/${nextData._id}`);
  };

  const handleVideos = (list) => {
    window.scrollTo(0, 0);
    if (list.preview) {
      setDisplayFile(false);
      return (window.location.href = `/preview/${courseId}/${list._id}`);
      // history.push(`/preview/${courseId}/${list._id}`);
    } else {
      SnackBar.error(`Video is not available for preview!!!`);
    }
    window.scrollTo(0, 0);
  };

  const courseVideos = () => {
    return courseVideosAndDocuments?.map(
      (list, idx) =>
        list.visibility &&
        !list.document && (
          <div key={list._id} className={classes.courseDisplay}>
            {!list.document ? (
              <>
                <RiVideoAddLine size={18} />
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="subtitle2"
                      onClick={topFunction}
                      component="div"
                      className={
                        list.preview
                          ? classes.courseTitleGreen
                          : classes.courseTitleRed
                      }
                      // onClick={() => handleVideos(list)}
                    >
                      {console.log(list)}
                      {list.title}-{" "}
                      {list.preview && (
                        <span sttyle={{ color: "red", marginLeft: "120px" }}>
                          Preview Available
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography align="right">
                      {new Date(1000 * (list.duration ? list.duration : 0))
                        .toISOString()
                        .substr(11, 8)
                        .replace("00:", "")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <RiFile3Fill size={18} />
                <Typography
                  variant="subtitle2"
                  component="div"
                  className={classes.courseTitleGreen}
                  onClick={() => handleFile(list)}
                >
                  {list.document.name}
                </Typography>
              </>
            )}
          </div>
        )
    );
  };

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleFile = (data) => {
    setFile(data.document.link);
    setDisplayFile(true);
    window.scrollTo(0, 0);
  };

  return (
    <Container maxWidth="xl" className="courseVideoViewContainer">
      <Typography variant="h5" component="div" className={classes.title}>
        {particularCourse?.title}
      </Typography>
      <div className="courseInfoViewContainer">
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            {!displayFile ? (
              <Card className={classes.root}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="div"
                >
                  {particularVideoInfo?.title}
                </Typography>
                <CardContent className={classes.cardContent}>
                  {isEmpty(
                    particularVideoInfo?.embedUrl
                  ) ? null : particularVideoInfo?.embedUrl.match(regex) ? (
                    <ReactPlayer
                      className="react-player"
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            modestbranding: 1,
                            autoplay: 1,
                            autohide: 0,
                            theme: "dark",
                            cc_load_policy: 1,
                            rel: 0,
                            iv_load_policy: 3,
                            mute: 0,
                            loop: 1,
                          },
                        },
                      }}
                      onDuration={(duration) => setDuration(duration)}
                      onProgress={(progress) =>
                        setProgress(Math.round(progress.playedSeconds))
                      }
                      url={particularVideoInfo?.embedUrl}
                      width="100%"
                      height="100%"
                      controls
                      sandbox={
                        "allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                      }
                    />
                  ) : (
                    <div className={classes.link}>
                      <ButtonField
                        name="Link"
                        buttonStyle={classes.linkStyle}
                        onClick={() =>
                          window.open(particularVideoInfo?.embedUrl, "_blank")
                        }
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            ) : (
              <FileView file={file} />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.card}>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.panelTitle}
                  >
                    Contents
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.panelTitle}
                    align="right"
                  >
                    {new Date(1000 * particularCourse?.duration || 0)
                      .toISOString()
                      .substr(11, 8)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.scrollable}>{courseVideos()} </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

FreeVideoView.propTypes = {
  getParticularCourse: PropTypes.func.isRequired,
  getParticularVideoOrInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  getVideosAndDocuments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
  accounts: state.accounts,
});

const mapDispatchToProps = {
  getParticularCourse,
  getParticularVideoOrInfo,
  getVideosAndDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeVideoView);
