import React from 'react';
import Header from './components/Header_Footer/Header';
// import Footer from './components/Header_Footer/Footer';

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            {/* <Footer /> */}
        </>
    );
};

export default Layout;