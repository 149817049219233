import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageHeader from "../../../reusableComponents/PageHeader";
import Tables from "../../MaterialTable/MaterialTable";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { getAccessLog } from "../../../redux/actions/AccountsActions";
import AccessLogFilters from "./AccessLogFilters";
import { Container,Paper } from "@material-ui/core"; 
import { makeStyles } from "@material-ui/core/styles"; 


const useStyles = makeStyles(theme => ({
  pageContent: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(0),
  },
}))
 

const AccessLogs = ({ getAccessLog, auth, accounts, history }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const { isAuthenticated } = auth;
  const { accessLogList } = accounts;

  useEffect(() => {
    //if(isEmpty(accountUsersList) && isAuthenticated) {
    getAccessLog();
    //    }
  }, []);

  const columns = [
    {
      title: "First Name",
      field: "name",
    },

    {
      title: "Email ID",
      field: "email",
    },

    {
      title: "Course Name",
      field: "courseName",
    },
    {
      title: "Video title",
      field: "chapter",
    },
    {
      title: "Video Duration",
      field: "videoDuration",
    },
    {
      title: "Video Progress",
      field: "videoProgress",
    },
    {
      title: "Last Viewed",
      field: "lastViewed",
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      //color: "#3d4977",
      color: "white",
    },
    scrollable: true,
    pageSize: 7,
    searchFieldStyle: {
      width: "100%",
    },
  };

  const data =
    accessLogList?.length > 0
      ? accessLogList?.map((list) => {
          const { videoDuration, videoProgress } = list;
          list.videoDuration = new Date(1000 * (parseInt(videoDuration) || 0))
            ?.toISOString()
            .substr(11, 8)
            .replace("00:", "");
          list.videoProgress = new Date(1000 * (parseInt(videoProgress) || 0))
            ?.toISOString()
            .substr(11, 8)
            .replace("00:", "");
          return list;
        })
      : [];
  
      const classes = useStyles();
  return (
    <> 
    <Container maxWidth="lg" > 
     <Paper className={classes.pageContent}>
      <PageHeader
        title="Access Log"
        subTitle="User Access Log"
        icon={<LibraryBooksIcon fontSize="large" />}
      /> 
      </Paper>
      <AccessLogFilters />
      <Tables
        title=""
        data={data}
        columns={columns}
        options={options}
        selectedRow={() => console.log("")}
      /> 
     </Container>
    </>
  );
};

AccessLogs.propTypes = {
  getAccessLog: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
});
const mapDispatchToProps = { getAccessLog };

export default connect(mapStateToProps, mapDispatchToProps)(AccessLogs);
